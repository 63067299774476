import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'

import tapi from '@/boot/tapi'

import { TemplateRecomendation, } from '@/store/types/Dataroom/Templates'
import { TemplateCrudRecomendation, } from '@/store/types/Dataroom/CrudTemplate'

export interface RecomendationState {
  templates: TemplateRecomendation[];
  templates_small: TemplateRecomendation[];
  templates_pages: number;
}

export interface TemplateGetInterface{
  page?: number;
  query?: string;
  title_contains?: string;
  name_cont?: string;
}

@Module({
  namespaced: true,
})
export default class RecomendationModule extends VuexModule {
  RecomendationState: RecomendationState = {
    templates: [],
    templates_small: [],
    templates_pages: 0,
  }

  // Mutations
  @Mutation
  public SET_USER_TEMPLATES (data: TemplateRecomendation[]) {
    this.RecomendationState.templates = data
  
  }
  @Mutation
  public SET_USER_TEMPLATES_SMALL (data: TemplateRecomendation[]) {
    this.RecomendationState.templates_small = data
  }

  @Mutation
  public SET_USER_TEMPLATES_PAGES (data: number) {
    this.RecomendationState.templates_pages = data
  }
  @Mutation
  public SET_NEW_TEMPLATE (data: TemplateRecomendation) {
    this.RecomendationState.templates.push(data)
  }
  @Mutation
  public UPDATE_TEMPLATES (data: TemplateRecomendation) {
    const templateIndex = this.RecomendationState.templates.findIndex((p) => p.id === data.id)
    Object.assign(this.RecomendationState.templates[templateIndex], data)
  }

  //Actions
  @Action
  public getTemplates (data: TemplateGetInterface) {
    return new Promise<TemplateRecomendation[]>((resolve, reject) => {
      tapi
        .GetTemplates(data)
        .then((data: any) => {
          this.context.commit('SET_USER_TEMPLATES', data.data.data)
          this.context.commit('SET_USER_TEMPLATES_PAGES', data.data.meta.pages)
          resolve(data.data)
        })
        .catch(reject)
    })
    
  }

  @Action
  public getTemplatesSmall () {
    return new Promise<TemplateRecomendation[]>((resolve, reject) => {
      tapi
        .GetTemplateSmall()
        .then((data: any) => {
          this.context.commit('SET_USER_TEMPLATES_SMALL', data.data.data)
          resolve(data.data)
        })
        .catch(reject)
    })
    
  }

  @Action
  public getTemplatesExist (data: TemplateCrudRecomendation) {
    return new Promise<TemplateRecomendation[]>((resolve, reject) => {
      tapi
        .GetTemplateExist(data)
        .then((data: any) => {
          resolve(data.data)
        })
        .catch(reject)
    })
    
  }

  @Action
  public getTemplatesbyId (data: number) {
    return new Promise((resolve,) => {
      tapi
        .GetTemplatesbyId(data)
        .then((data: any) => {
          resolve(data)
        })
    })
  }

  @Action
  public editTemplates (template: TemplateCrudRecomendation) {
    return new Promise((resolve, reject) => {
      tapi
        .EditTemplate(template)
        .then((data: any) => {
          this.context.commit('UPDATE_TEMPLATES', data.data.recommendation_template)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public createTemplates (template: TemplateCrudRecomendation) {
    return new Promise((resolve, reject) => {
      tapi
        .CreateTemplate(template)
        .then((data: any) => {
          this.context.commit('SET_NEW_TEMPLATE', data.data.recommendation_template)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public deleteTemplate (template: string[]) {
    return new Promise((resolve, reject) => {
      tapi
        .DeleteTemplate(template)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  // Getters 
  get getTemplatesPages () {
    return this.RecomendationState.templates_pages
  }

  get getTemplatesGetter () {
    return this.RecomendationState.templates
  }

  get getTemplatesGetterSmall () {
    return this.RecomendationState.templates_small
  }
}
