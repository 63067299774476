import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import router from '@/router'
import tapi from '@/boot/tapi'
import { GapInterface, } from '@/store/types/Analysis/GapAnalitic'
import { ControListInterface, } from '@/store/types/Analysis/ControlList'
import { DatesSnapshotInterface, } from '@/store/types/Analysis/DatesSnapshot'
import { GapQuestion, } from '@/store/types/Analysis/GapQuestions'
import { EditGapInterface, } from '@/store/types/Analysis/EditGap'
import { format, } from 'date-fns'

// eslint-disable @typescript-eslint/no-explicit-any

export interface GapState {
  // 1er Piso
  categories: GapInterface[];
  version_categories: number;
  // 2do Piso
  controlList: ControListInterface[];
  // Selecteds
  detailSelected: ControListInterface;
  categorieSelected: GapInterface;
  gapAnalysis: GapQuestion;
  publicGap: boolean;
  advancedGap: boolean;
  // Dates for snapshot
  dates: DatesSnapshotInterface[];
  historyMode: boolean;
  breadCrumb: string[];
  dateVersion: string;
  // Public Compliance Summary
  completedInformation: string;
  pendingInformation: string;
  frameworkSelectedHistory: string;
  frameworkIdSelectedHistory: number;
}
export interface GapControlList{
  categoryId:number;
  frameworkId?:string;
}

@Module({
  namespaced: true,
})
export default class GapModule extends VuexModule {
  GapState: GapState = {
    categories: [],
    version_categories: 0,
    controlList: [],
    detailSelected: {
      id: 0,
      control_id: '',
      question: '',
      status: '',
      updated_at: '',
    },
    categorieSelected: {
      id: 0,
      control_id: '',
      name: '',
      revised_controls: 0,
      total_controls: 0,
      status: '',
      updated_at: '',
    },
    gapAnalysis: {
      id: 0,
      apply: false,
      status: '',
      criticality: '',
      question: '',
      created_at: '',
      updated_at: '',
    },
    publicGap: false,
    advancedGap: false,
    dates: [],
    historyMode: false,
    dateVersion: format(new Date(), 'yyyy-MM-dd'),
    breadCrumb: [],
    completedInformation: '',
    pendingInformation: '',
    frameworkSelectedHistory: '',
    frameworkIdSelectedHistory: 0,
  }

  // Mutations
  @Mutation
  public SET_GAP_CATEGORIES (data: GapInterface[]) {
    this.GapState.categories = data
  }
  @Mutation
  public SET_GAP_CONTROL_LIST (data: ControListInterface[]) {
    this.GapState.controlList = data
  }
  @Mutation
  public SET_GAP_ANALYSIS (data: ControListInterface[]) {
    this.GapState.controlList = data
  }
  @Mutation
  public SET_CONTROL_SELECTED (data: GapInterface) {
    this.GapState.categorieSelected = data
  }
  @Mutation
  public SET_DETAIL_SELECTED (data: any) {
    this.GapState.detailSelected = data
  }
  @Mutation
  public SET_GAP_ANALYSIS_LIST (data: GapQuestion) {
    this.GapState.gapAnalysis = data
  }
  @Mutation
  public SET_PUBLIC_GAP (data: boolean) {
    this.GapState.publicGap = data
  }
  @Mutation
  public SET_ADVANCE_GAP (data: boolean) {
    this.GapState.advancedGap = data
  }
  @Mutation
  public SET_DATES (data: DatesSnapshotInterface[]) {
    this.GapState.dates = data
  }
  @Mutation
  public SET_HISTORY_MODE (data: boolean) {
    this.GapState.historyMode = data
  }

  @Mutation
  public SET_VERSION_CATEGORIES (data: number) {
    this.GapState.version_categories = data
  }

  @Mutation
  public SET_FRAMEWOK_HISTORY (data: string) {
    this.GapState.frameworkSelectedHistory = data
  }
  @Mutation
  public SET_FRAMEWOK_HISTORY_ID (data: number) {
    this.GapState.frameworkIdSelectedHistory = data
  }

  @Mutation
  public SET_DATE_VERSION (data: string) {
    this.GapState.dateVersion = data
  }

  @Mutation
  public SET_COMPLETED_INFO (data: string) {
    this.GapState.completedInformation = data
  }

  @Mutation
  public SET_PENDING_INFO (data: string) {
    this.GapState.pendingInformation = data
  }

  // Actions
  @Action({ rawError: true, })
  public getGapCategories (data: number) {
    return new Promise<GapInterface[]>((resolve, reject) => { 
      tapi
        .GetGapCategories(data)
        .then((data: any) => {
          this.context.commit('SET_GAP_CATEGORIES', data.data.gap_analysis_categories)
          this.context.commit('SET_PUBLIC_GAP', data.data.publish_gap_analysis)
          this.context.commit('SET_ADVANCE_GAP', data.data.publish_advance)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action({ rawError: true, })
  public getGapControlList (data:GapControlList) {
    return new Promise<ControListInterface[]>((resolve, reject) => {
      tapi
        .GetControlList(data.categoryId,data.frameworkId)
        .then((data: any) => {
          this.context.commit('SET_GAP_CONTROL_LIST', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action({ rawError: true, })
  public GetGapAnalysisControl (data: number) {
    return new Promise<ControListInterface[]>((resolve, reject) => {
      tapi
        .GetGapAnalysisControl(data)
        .then((data: any) => {
          this.context.commit('SET_GAP_ANALYSIS_LIST', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public editGap (template: EditGapInterface) {
    return new Promise((resolve, reject) => {
      tapi
        .EditGapAnalysys(template)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public publicGap (data: number) {
    return new Promise((resolve, reject) => {
      tapi
        .PublicGapAnalysis(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public exportGap (data: number) {
    return new Promise((resolve, reject) => {
      tapi
        .ExportGapAnalysis(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action({ rawError: true, })
  public exportJira (data: number) {
    return new Promise((resolve, reject) => {
      tapi
        .ExportToJira(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action({ rawError: true, })
  public getDatesSnapshot (data: number) {
    return new Promise<DatesSnapshotInterface[]>((resolve) => {
      tapi
        .GetDatesSnapshots(data)
        .then((data: any) => {
          this.context.commit('SET_DATES', data.data)
          resolve(data)
        })
        .catch((reject) => {
          if (reject.response.status === 401) {
            router.push('/')
            location.reload()
          }
        })
    })
  }

  // getters
  get getPublicGap () {
    return {
      public: this.GapState.publicGap,
    }
  }
  get getAdvancedGap () {
    return {
      public: this.GapState.advancedGap,
    }
  }
  get getCategorieSelected () {
    return {
      selected: this.GapState.categorieSelected,
    }
  }
  get getDetailSelected () {
    return {
      selected: this.GapState.gapAnalysis,
    }
  }
  get getHistoryMode () {
    return {
      historyMode: this.GapState.historyMode,
    }
  }

  get getVersionCategories () {
    return {
      version_categories: this.GapState.version_categories,
    }
  }

  get getCompletedInformation () {
    return {
      completedInformation: this.GapState.completedInformation,
    }
  }

  get getPendingInformation () {
    return {
      pendingInformation: this.GapState.pendingInformation,
    }
  }
  get getDateVersion () {
    return {
      dateVersion: this.GapState.dateVersion,
    }
  }
  get getFrameworkSelectedHistory () {
    return {
      name: this.GapState.frameworkSelectedHistory,
    }
  }
  get getframeworkIdSelectedHistory () {
    return {
      id: this.GapState.frameworkIdSelectedHistory,
    }
  }
}
