import { GrowthBook } from "@growthbook/growthbook";

const getFeaturesJson = async (featuresEndpoint: any) => {
  const response = await fetch(featuresEndpoint);
  return await response.json();
}

export const GrowthBookVuePlugin = {
  install: function (Vue: any, { featuresEndpoint = '', enableDevMode = false }) {
    let growthBook: any = null;

    Vue.prototype.initGrowthBook = async function initGrowthBook() {
      if (growthBook) {
        return Promise.resolve(growthBook);
      }

      try {
        const json = await getFeaturesJson(featuresEndpoint);
        growthBook = new GrowthBook({
          enableDevMode,
        });

        growthBook.setFeatures(json.features);
        return growthBook;
      } catch (error) {
        console.error("GrowthBook Vue plugin initialization error", error);
        return null;
      }
    }
  }
}
