import { format } from 'date-fns'
import add from 'date-fns/add'
import { es } from 'date-fns/locale'
import { ControListInterface } from '@/store/types/Analysis/ControlList'
const filters = [
  {
    name: 'navigationFormat',
    execute: (value: ControListInterface) => {
      if (value.control_id) {
        return value.control_id
      } else {
        return value.code
      }
    },
  },
  {
    name: 'navigationFormattitle',
    execute: (value: ControListInterface) => {
      if (value.control_id) {
        return value.name
      } else {
        return value.name
      }
    },
  },
  {
    name: 'dateFormat',
    execute: (date: string) => {
      if (date) {
        return format(new Date(date), "dd MMM yyyy'", { locale: es })
      } else {
        return
      }
    },
  },
  {
    name: 'dateFormatAdd',
    execute: (date: string) => {
      if (date) {
        const dateAux = add(new Date(date), { days: 1 })
        return format(new Date(dateAux), "MMM dd',' yyyy", { locale: es })
      } else {
        return
      }
    },
  },
  {
    name: 'dateFormatMaintenance',
    execute: (date: string) => {
      if (date) {
        const dateAux = add(new Date(date), { days: 1 })
        return format(new Date(dateAux), "dd'/'MM'/'yyyy'", { locale: es })
      } else {
        return
      }
    },
  },
  {
    name: 'dateFormatMaintenanceNotAdd',
    execute: (date: string) => {
      if (date) {
        try {
          const dateObject = new Date(date)
          const utcDate = new Date(dateObject.getUTCFullYear(), dateObject.getUTCMonth(), dateObject.getUTCDate())
          return format(utcDate, "dd'/'MM'/'yyyy'", { locale: es })
        } catch (error) {
          console.error('Error en dateFormatMaintenanceNotAdd:', error)
          return ''
        }
      } else {
        return ''
      }
    },
  },
  {
    name: 'dateFormatText',
    execute: (date: string) => {
      if (date) {
        return format(new Date(date), 'EEEE, dd MMMM y', { locale: es })
      } else {
        return
      }
    },
  },
  {
    name: 'spanishStatus',
    execute: (value: string) => {
      switch (value) {
        case 'unrelieved':
          return 'Sin relevar'
        case 'relieved':
          return 'Relevado'
        case 'complies':
          return 'Cumple'
        case 'partially_complied':
          return 'Cumple parcialmente'
        case 'uncomplied':
          return 'No cumple'
        case 'inapplicable':
          return 'No aplica'
        case 'relieving':
          return 'En relevamiento'
        default:
          return '-'
      }
    },
  },
  {
    name: 'spanishStatusDataroom',
    execute: (value: string) => {
      switch (value) {
        case 'published':
          return 'Publicado'
        case 'obsolete':
          return 'Obsoleto'
        default:
          return '-'
      }
    },
  },
  {
    name: 'englishStatus',
    execute: (value: string) => {
      switch (value) {
        case 'Sin relevar':
          return 'unrelieved'
        case 'Relevado':
          return 'relieved'
        case 'Cumple':
          return 'complies'
        case 'Cumple parcialmente':
          return 'partially_complied'
        case 'No cumple':
          return 'uncomplied'
        case 'No aplica':
          return 'inapplicable'
        case 'En relevamiento':
        case 'En Relevamiento':
          return 'relieving'
        default:
          return '-'
      }
    },
  },
  {
    name: 'spanishLevel',
    execute: (value: string) => {
      switch (value) {
        case 'high':
          return 'Alta'
        case 'medium':
          return 'Medio'
        case 'low':
          return 'Baja'
        case 'neither':
          return 'No existe'
        default:
          return '-'
      }
    },
  },
  {
    name: 'applyStatus',
    execute: (value: boolean) => {
      switch (value) {
        case true:
          return 'Aplica'
        case false:
          return 'No aplica'
        default:
          return '-'
      }
    },
  },
  {
    name: 'complianceStatus',
    execute: (value: boolean) => {
      switch (value) {
        case true:
          return 'Cumple'
        case false:
          return 'No cumple'
        default:
          return '-'
      }
    },
  },
  {
    name: 'truncateChip',
    execute: (text: string, length = 18) => {
      const extension: any = text.split('.').pop()
      if (extension.length <= 5) {
        return text.length >= length ? `${text.substring(0, length)}...${extension}` : text
      } else {
        return text.length >= length ? `${text.substring(0, length)}...` : text
      }
    },
  },
  {
    name: 'contactsInformation',
    execute: (value: string) => {
      switch (value) {
        case 'main':
          return 'Responsable Principal'
        // case 'admin':
        //   return 'Administrador'
        case 'technology_and_development':
          return 'Tecnología y Desarrollo'
        case 'informatic_security':
          return 'Seguridad Informática'
        case 'physical_security':
          return 'Seguridad Física'
        case 'human_resources':
          return 'Recursos Humanos'
        case 'contractual':
          return 'Referente Contractual'
        default:
          return '-'
      }
    },
  },
  {
    name: 'statusFilter',
    execute: (value: string) => {
      switch (value) {
        case 'UNBIDDEN':
          return 'Sin invitación'
        case 'ACTIVE':
          return 'Activo'
        case 'PENDING':
          return 'Pendiente'
        case 'DISABLED':
          return 'Desactivada'
        default:
          return '-'
      }
    },
  },
  {
    name: 'frecuencyStatus',
    execute: (value: string) => {
      switch (value) {
        case 'six_months':
          return 'Revisión cada 6 meses'
        case 'three_months':
          return 'Revisión cada 3 meses'
        case 'one_year':
          return 'Revisión cada 1 año'
        default:
          return 'Revisión cada 1 año'
      }
    },
  },
  {
    name: 'statusValue',
    execute: (value: string) => {
      switch (value) {
        case 'pending':
          return 'Pendiente'
        case 'progress':
          return 'En curso'
        case 'hackmetrix_review':
          return 'En revisión Hackmetrix'
        case 'company_review':
          return 'En revisión Cliente'
        case 'hackmetrix_approved':
          return 'Por aprobar Cliente'
        case 'done':
          return 'Terminado'
        default:
          return value
      }
    },
  },
  {
    name: 'statusValueBox',
    execute: (value: string) => {
      switch (value) {
        case 'pending':
          return 'Pendiente'
        case 'progress':
          return 'En curso'
        case 'hackmetrix_review':
          return 'Esperando revisión'
        case 'company_review':
          return 'Por corregir'
        case 'company_approved':
          return 'Por aprobar'
        case 'upload_documentation':
          return 'Subir documento final'
        case 'notify_documentation':
          return 'Por comunicar'
        case 'done':
          return 'Terminado'
        case 'total':
          return 'Total'
        default:
          return value
      }
    },
  },
  {
    name: 'statusValueV2',
    execute: (value: string) => {
      switch (value) {
        case 'start':
          return 'Pendiente'
        case 'preparation':
          return 'En curso'
        case 'review':
          return 'Esperando revisión'
        case 'corrections':
          return 'Por corregir'
        case 'approbation':
          return 'Por aprobar'
        case 'final_document':
          return 'Subir documento final'
        case 'comunication':
          return 'Por comunicar'
        case 'done':
          return 'Terminado'
        default:
          return value
      }
    },
  },
  {
    name: 'transformStatus',
    execute: (value: string) => {
      switch (value) {
        case 'pending':
          return 'Pendiente'
        case 'progress':
          return 'En curso'
        case 'hackmetrix_review':
          return 'En revisión Hackmetrix'
        case 'company_review':
          return 'En revisión Cliente'
        case 'hackmetrix_approved':
          return 'Aprobado por Cliente'
        case 'done':
          return 'Terminado'
        default:
          return value
      }
    },
  },
  {
    name: 'stepsFilter',
    execute: (value: string) => {
      switch (value) {
        case 'start':
          return 'Comenzar actividad '
        case 'preparation':
          return 'Elaborar documento editable'
        case 'review':
          return 'Hackmetrix está revisando tu documento editable'
        case 'corrections':
          return 'Corregir el documento editable'
        case 'approbation':
          return 'Aprobar por parte del responsable o comité'
        case 'final_document':
          return 'Subir documento final'
        case 'comunication':
          return 'Comunicar documento'
        case 'done':
          return 'Terminado'
        default:
          return value
      }
    },
  },
  {
    name: 'capitalizeChannel',
    execute: (value: string) => {
      switch (value) {
        case 'email':
          return 'Correo Electrónico'
        case 'slack':
          return 'Slack'
        case 'hangout':
          return 'Hangout'
        case 'teams':
          return 'Teams'
        case 'whatsapp':
          return 'Whatsapp'
        case 'other':
          return 'Otro'
      }
    },
  },
  {
    name: 'documentationLevel',
    execute: (value: string) => {
      switch (value) {
        case 'high':
          return 'Alta'
        case 'medium':
          return 'Medio'
        case 'low':
          return 'Bajo'
        case 'neither':
          return 'No existe'
      }
    },
  },
  {
    name: 'evidenceTitle',
    execute: (value: string) => {
      switch (value) {
        case 'complies':
          return '¿Por qué se cumple?'
        case 'partially_complied':
          return '¿Por qué se cumple parcialmente?'
        case 'uncomplied':
          return '¿Por qué no se cumple?'
        case 'inapplicable':
          return '¿Por qué no aplica para este proyecto?'
        default:
          return '-'
      }
    },
  },
  {
    name: 'recommendationTitle',
    execute: (value: string) => {
      switch (value) {
        case 'complies':
          return '¿Qué se puede mejorar?'
        case 'partially_complied':
        case 'uncomplied':
        case 'inapplicable':
          return '¿Qué se necesita para cumplir?'
        default:
          return '-'
      }
    },
  },
  {
    name: 'supportTitle',
    execute: (value: string) => {
      switch (value) {
        case 'recommendation':
          return 'Recomendación'
        case 'evidence':
          return 'Evidencia'
        case 'justification':
          return 'Justificación'
        default:
          return '-'
      }
    },
  },
]

export default filters
