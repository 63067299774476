// eslint-disable @typescript-eslint/no-explicit-any
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'

import router from '@/router'
import tapi from '@/boot/tapi'
import { BusinessInterface, BusinessClientInterface } from '@/store/types/Business/Business'
import { CrudBusiness } from '@/store/types/Business/CrudBusiness'
import { ContactsInterface } from '@/store/types/Business/Contacts'
import { ResetPasswordInterface } from '@/store/types/ResetPassword/Index'
import { UsersInterface } from '@/store/types/Business/Users'
export interface BusinessState {
  business: BusinessInterface[]
  businessSelected: BusinessInterface
  businessClient: BusinessClientInterface
  contact_modal: boolean
  business_pages: number
}

export interface TemplateGetInterface {
  page?: number
  query?: string
  title_contains?: string
  name_cont?: string
}

@Module({
  namespaced: true,
})
export default class BusinessModule extends VuexModule {
  BusinessState: BusinessState = {
    business: [],
    businessSelected: {
      id: 0,
      name: '',
      rut_cuit: '',
      description: '',
      documentation_level: '',
      architecture_description: '',
      frameworks: [],
      contacts: [],
      users: [],
      active: true,
      architectures: [],
      channels: [],
      kickoff_data: [],
      kickoff_url: [],
      saqs: {
        id: 0,
        name: '',
      },
      domains: [],
    },
    businessClient: {
      folder_id: 0,
      id: 0,
      name: '',
    },
    contact_modal: false,
    business_pages: 0,
  }

  // Mutations
  @Mutation
  public SET_USER_BUSINESS(data: BusinessInterface[]) {
    this.BusinessState.business = data
  }
  @Mutation
  public SET_USER_BUSINESS_PAGES(data: number) {
    this.BusinessState.business_pages = data
  }
  @Mutation
  public SET_NEW_BUSINESS(data: BusinessInterface) {
    this.BusinessState.business.push(data)
  }
  @Mutation
  public SET_CONTACTS_BUSINESS(data: ContactsInterface[]) {
    this.BusinessState.businessSelected = { ...this.BusinessState.businessSelected, contacts: data }
  }
  @Mutation
  public SET_USERS_BUSINESS({ data, flag }: { data: UsersInterface[]; flag: boolean }) {
    if (flag) {
      this.BusinessState.businessSelected = {
        ...this.BusinessState.businessSelected,
        users: [...(this.BusinessState.businessSelected.users ?? []), ...data],
      }
    } else {
      this.BusinessState.businessSelected = {
        ...this.BusinessState.businessSelected,
        users: [...data],
      }
    }
  }
  @Mutation
  public SET_BUSINESS_SELECTED(data: BusinessInterface) {
    this.BusinessState.businessSelected = data
  }
  @Mutation
  public SET_BUSINESS_CLIENT(data: BusinessClientInterface) {
    this.BusinessState.businessClient = data
  }
  @Mutation
  public SET_BUSINESS_(data: BusinessClientInterface) {
    this.BusinessState.businessClient = data
  }
  @Mutation
  public UPDATE_BUSINESS(data: BusinessInterface) {
    const templateIndex = this.BusinessState.business.findIndex((p) => p.id === data.id)
    Object.assign(this.BusinessState.business[templateIndex], data)
  }

  @Mutation
  public OPEN_MODAL() {
    this.BusinessState.contact_modal = !this.BusinessState.contact_modal
  }

  @Action openModal() {
    this.context.commit('OPEN_MODAL')
  }

  //Actions Users
  @Action
  public getUsers({ data, flag }: { data: Array<any>; flag: boolean }) {
    return new Promise<UsersInterface[]>((resolve, reject) => {
      tapi
        .GetCompanyUsers(data)
        .then((data: any) => {
          this.context.commit('SET_USERS_BUSINESS', { data: data.data.data.users, flag })
          resolve(data)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            router.push('/')
            location.reload()
          } else if (error.response && error.response.data.statusCode !== 200) {
            console.error(
              `Error: ${error.response.data.statusCode}, Message: ${error.response.data.message}`
            )
            reject(error instanceof Error ? error : new Error(error))
          }
        })
    })
  }
  @Action
  public getModules() {
    return new Promise<UsersInterface[]>((resolve, reject) => {
      tapi
        .GetModule()
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })

  } 
  @Action
  public blockCompanyUserId(data: any) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .BlockCompanyUserId(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public unblockCompanyUserId(data: any) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .UnblockCompanyUserId(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public sendChangePasswordUser(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .SendChangePasswordUser(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public editCompanyUser(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .EditCompanyUser(data.user_id, data.userEditData)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  //Actions Business + Contacts
  @Action
  public getBusiness(data: TemplateGetInterface) {
    return new Promise<BusinessInterface[]>((resolve) => {
      tapi
        .GetBusiness(data)
        .then((data: any) => {
          this.context.commit('SET_USER_BUSINESS', data.data.data)
          this.context.commit('SET_USER_BUSINESS_PAGES', data.data.meta.pages)
          resolve(data)
        })
        .catch((reject) => {
          if (reject.response.status === 401) {
            router.push('/')
            location.reload()
          }
        })
    })
  }
  @Action
  public getBusinessDetail(data: number) {
    return new Promise<BusinessInterface[]>((resolve) => {
      tapi
        .GetDetailBusiness(data)
        .then((data: any) => {
          this.context.commit('SET_BUSINESS_SELECTED', data.data)
          resolve(data)
        })
        .catch((reject) => {
          if (reject.response.status === 401) {
            router.push('/')
            location.reload()
          }
        })
    })
  }
  @Action
  public getContacts(id: number) {
    return new Promise<ContactsInterface[]>((resolve) => {
      tapi
        .GetCompanyContacts(id)
        .then((data: any) => {
          this.context.commit('SET_CONTACTS_BUSINESS', data.data)
          resolve(data)
        })
        .catch((reject) => {
          if (reject.response.status === 401) {
            router.push('/')
            location.reload()
          }
        })
    })
  }

  @Action
  public SetCompanyMainContactId(data: any) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .SetCompanyMainContactId(data.id, data.contact_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public deactivateContactId(data: any) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .DeactivateContactId(data.id, data.contact_id, data.new_main_contact_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public activateContactId(data: any) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .ActivateContactId(data.id, data.contact_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public editBusiness(template: CrudBusiness) {
    return new Promise((resolve, reject) => {
      tapi
        .EditBusiness(template)
        .then((data: any) => {
          this.context.commit('UPDATE_BUSINESS', data.data.company)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public softDeleteBusiness(template: CrudBusiness) {
    return new Promise((resolve, reject) => {
      tapi
        .SoftDeleteBusiness(template)
        .then((data: any) => {
          this.context.commit('UPDATE_BUSINESS', data.data.company)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public createBusiness(template: CrudBusiness) {
    return new Promise((resolve, reject) => {
      tapi
        .CreateBusiness(template)
        .then((data: any) => {
          this.context.commit('SET_NEW_BUSINESS', data.data.company)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public deleteBusiness(template: CrudBusiness) {
    return new Promise((resolve, reject) => {
      tapi
        .DeleteBusiness(template)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public PostCompanyContact(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .PostCompanyContact(data.id, data.contact)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public PostEditCompanyContact(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .PostEditCompanyContact(data.id, data.contact.id, data.contact)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public deleteContact(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .deleteCompanyContact(data.id, data.contact_id, data.new_main_contact_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public sendInvite(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .SendInvite(data.id, data.contact_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public reSendInvite(data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .reSendInvite(data.id, data.contact_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public confirmUser(data: ResetPasswordInterface) {
    return new Promise((resolve, reject) => {
      tapi
        .ConfirmUser(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public ExistBusiness(data: string) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .ExistBusiness(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public ExistHubspotId(hubspot_id: number) {
    return new Promise<string>((resolve, reject) => {
      tapi
        .HubspotIdAvailibility(hubspot_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  get getSelectedProduct() {
    return this.BusinessState.businessSelected
  }

  get getRelivedFramework() {
    let aux = true

    if (this.BusinessState.businessSelected?.frameworks) {
      this.BusinessState.businessSelected.frameworks.forEach((element: any) => {
        if (element.status == 'relieved') {
          aux = false
        }
      })
    }

    return aux
  }

  get getBusinessClient() {
    return this.BusinessState.businessClient
  }

  get getBusinessList() {
    return this.BusinessState.business
  }

  get getModalStatus() {
    return this.BusinessState.contact_modal
  }

  get getBusinessFrameworks() {
    return this.BusinessState.businessSelected
  }

  get getBusinessChannels() {
    return this.BusinessState.businessSelected.channels
  }
  get getBusinessPages() {
    return this.BusinessState.business_pages
  }
}
