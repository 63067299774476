// eslint-disable @typescript-eslint/no-explicit-any
import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'

import tapi from '@/boot/tapi'
import { FoldersInterface, } from '@/store/types/Dataroom/Folder'
import { DataroomTemplate, FilesFoldersTemplate, } from '@/store/types/Dataroom/DataroomTemplate'

export interface DataRoomState {
  folders: FoldersInterface[];
  files: DataroomTemplate[];
  folderCreate: number;
  filesfolders: FilesFoldersTemplate[];
  folderSelected: number;
}

export interface FileUploadInterface {
  filename: string;
  url?: string | undefined;
  data?: string | undefined;
  dataFormData?: any | undefined;
  size?: string | undefined;
  new_file?: boolean;
  name?: string;
  type?: string;
  extension?: string;
}

export interface FileUploadApi {
  id?: string;
  name?: string;
}

@Module({
  namespaced: true,
})
export default class DataRoomModule extends VuexModule {
  DataRoomState: DataRoomState = {
    folders: [],
    files: [],
    folderCreate: 0,
    filesfolders: [],
    folderSelected: 0,
  }

  // Mutations
  @Mutation
  public INITIAL_STATE () {
    this.DataRoomState.files = []
  }
  @Mutation
  public SET_USER_FOLDERS (data: FoldersInterface[]) {
    this.DataRoomState.folders = data
  }
  @Mutation
  public SET_USER_FILES (data: DataroomTemplate[]) {
    this.DataRoomState.files = data
  }
  @Mutation
  public SET_USER_FILES_FOLDERS (data: DataroomTemplate[]) {
    this.DataRoomState.filesfolders = data
  }
  @Mutation
  public SET_NEW_FOLDER (data: FoldersInterface) {
    this.DataRoomState.folders.push(data)
  }
  @Mutation
  public SET_FOLDER_SELECTED (data: number) {
    this.DataRoomState.folderCreate = data
  }
  @Mutation
  public SET_FOLDER_SELECTED_DELETE (data: number) {
    this.DataRoomState.folderSelected = data
  }
  @Mutation
  public UPDATE_FOLDER (data: FoldersInterface) {
    const templateIndex = this.DataRoomState.folders.findIndex((p) => p.id === data.id)
    Object.assign(this.DataRoomState.folders[templateIndex], data)
  }

  //Actions
  @Action({rawError: true})
  public getFolders (template: number) {
    return new Promise<FoldersInterface[]>((resolve, reject) => {
      tapi
        .GetFolders(template)
        .then((data: any) => {
          this.context.commit('SET_USER_FOLDERS', data.data.data.folders)
          this.context.commit('SET_USER_FILES', data.data.data.files)
          this.context.commit('SET_USER_FILES_FOLDERS', [
            ...data.data.data.folders,
            ...data.data.data.files,
          ])
          resolve(data)
        }).catch(reject)
    })
  }
  @Action
  public createFolder (data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .CreateFolder(data, this.DataRoomState.folderCreate)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public editFolder (template: FoldersInterface) {
    return new Promise((resolve, reject) => {
      tapi
        .EditFolder(template)
        .then((data: any) => {
          this.context.commit('UPDATE_FOLDER', data.data.folder)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public deleteFolder (data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .DeleteFolder(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public deleteFile (data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .DeleteFile(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public uploadFile (data: any) {
    return new Promise((resolve, reject) => {
      tapi
        .UploadFile(this.DataRoomState.folderCreate, data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  // Getters

  get getFolderSelected () {
    return this.DataRoomState.folderSelected
  }
}
