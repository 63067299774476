import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import tapi from '@/boot/tapi'
import {
  CategoryInterface,
  ComplienceGrahpInterface,
  CompliesInterface,
} from '@/store/types/metrics/Compllies'

export interface MetricsState {
  totalGraph: ComplienceGrahpInterface;
  categories: CategoryInterface[];
  uncomplies: CompliesInterface;
  complies: CompliesInterface;
}

@Module({
  namespaced: true,
})
export default class MetricsModule extends VuexModule {
  MetricsState: MetricsState = {
    categories: [],
    totalGraph: {
      categories: 0,
      complies: 0,
      inapplicable: 0,
      partially_complied: 0,
      questions: 0,
      uncomplied: 0,
      unrelieved: 0,
    },
    uncomplies: {
      partially_complied: 0,
      total: 0,
      total_controls: 0,
      uncomplied: 0,
      total_complies: 0,
    },
    complies: {
      partially_complied: 0,
      total: 0,
      total_controls: 0,
      uncomplied: 0,
      total_complies: 0,
    },
  }

  @Mutation
  public SET_COMPLIES (data: CompliesInterface) {
    this.MetricsState.complies = data
  }
  @Mutation
  public SET_UNCOMPLIES (data: CompliesInterface) {
    this.MetricsState.uncomplies = data
  }
  @Mutation
  public SET_CATEGORIES (data: CompliesInterface[]) {
    this.MetricsState.categories = data
  }
  @Mutation
  public SET_TOTAL_GRAPH (data: ComplienceGrahpInterface) {
    this.MetricsState.totalGraph = data
  }


  @Action({rawError: true})
  public getCompliesCat () {
    return new Promise((resolve, reject) => {
      tapi
        .getCompliesCat()
        .then((data: any) => {
          this.context.commit('SET_CATEGORIES', data.data.category)
          this.context.commit('SET_TOTAL_GRAPH', data.data.percentage_by_status)
          resolve(data)
        })
        .catch(reject)
    })
  }

  // Get Percents By Framework id

  @Action
  public getCompliesCatbyFramework (framework: number) {
    return new Promise((resolve, reject) => {
      tapi
        .getCompliesCatbyFramework(framework)
        .then((data: any) => {
          this.context.commit('SET_CATEGORIES', data.data.category)
          this.context.commit('SET_TOTAL_GRAPH', data.data.percentage_by_status)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public getCompliesTotal (framework_id?: number) {
    return new Promise((resolve, reject) => {
      tapi
        .getCompliesTotal(framework_id ? framework_id : undefined)
        .then((data: any) => {
          this.context.commit('SET_COMPLIES', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public getUncomplies (framework_id?: number) {
    return new Promise((resolve, reject) => {
      tapi
        .getUncomplies(framework_id ? framework_id : undefined)
        .then((data: any) => {
          this.context.commit('SET_UNCOMPLIES', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  get getComplies () {
    return {
      complies: this.MetricsState.complies,
      uncomplies: this.MetricsState.uncomplies,
    }
  }

  get getSelectedCats () {
    return this.MetricsState.categories
  }

  get getTotalGraph () {
    return this.MetricsState.totalGraph
  }
}
