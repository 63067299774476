import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import {
  ActivityInterface,
  ActivityComment,
  UrlInterface,
} from '@/store/types/Activities/ActivitiesGeneral'
import tapi from '@/boot/tapi'

export interface ActivitiesState {
  CompanyActivities: ActivityInterface[];
  selectedFrimeworkActivities: ActivityInterface[];
  SelectedActivity: ActivityInterface;
  comments: ActivityComment[];
  SelectedActivityArray: ActivityInterface[];
  activity_urls: UrlInterface[];
  metadata: {};
  modal_finish: boolean;
  status_filter: {};
}

@Module({
  namespaced: true,
})
export default class ActivitiesModule extends VuexModule {
  ActivitiesState: ActivitiesState = {
    CompanyActivities: [],
    selectedFrimeworkActivities: [],
    SelectedActivity: {
      open: false,
    },
    comments: [],
    SelectedActivityArray: [],
    activity_urls: [],
    metadata: {},
    modal_finish: true,
    status_filter: []
  }

  // Mutations
  @Mutation
  public SET_COMPANY_ACTIVITIES (data: ActivityInterface[]) {
    this.ActivitiesState.CompanyActivities = data
  }

  @Mutation
  public SET_COMPANY_ACTIVITIES_BY_FRIMEWORK (data: ActivityInterface[]) {
    this.ActivitiesState.selectedFrimeworkActivities = data
  }

  @Mutation
  public SET_SELECTED_ACTIVITY (data: ActivityInterface) {
    this.ActivitiesState.SelectedActivity = data
    if (this.ActivitiesState.SelectedActivity.activity_urls === null) {
      this.ActivitiesState.SelectedActivity.activity_urls = []
    }
  }
  @Mutation
  public SET_TO_EXPIRED (toExpired: boolean) {
    this.ActivitiesState.SelectedActivity.toExpired = toExpired
  }

  @Mutation
  public SET_SELECTED_ACTIVITY_ARRAY (data: ActivityInterface[]) {
    this.ActivitiesState.SelectedActivityArray = data
  }

  @Mutation
  public SET_COMMENTS (data: ActivityComment[]) {
    this.ActivitiesState.comments = data
  }

  @Mutation
  public SET_METADATA (data: any) {
    this.ActivitiesState.metadata = data
  }

  @Mutation
  public SET_COMMENTS_ARRRAY (data: any) {
    this.ActivitiesState.SelectedActivityArray[data.index]['comments'] = data.messages
  }

  @Mutation
  public SET_OPEN_MODAL () {
    this.ActivitiesState.modal_finish = !this.ActivitiesState.modal_finish
  }

  @Mutation
  public SET_STATUS_FILTER (status: any) {
    this.ActivitiesState.status_filter = status;
  }
 
  // Action
  @Action({rawError: true})
  public getCompanyActivities (data: any) {
    return new Promise<{activities: ActivitiesState[], status: number}>((resolve, reject) => {
      tapi
        .getCompanyActivitiesV2(data)
        .then((data: any) => {
          const activities = data.data.data
          const pushActivities: ActivityInterface[] = []
          activities.forEach((element: any) => {
            element.activities.forEach((data: any) => {
              pushActivities.push(data)
            })
          })

          this.context.commit('SET_COMPANY_ACTIVITIES', pushActivities)
          this.context.commit('SET_METADATA', data.data.meta)
          this.context.commit('SET_STATUS_FILTER', data.data.meta)
          resolve({activities: data.data.data, status: data.status})
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action
  public getActivitiesByFrimework (control_id?: number) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .getActivitiesByFrimework(control_id)
        .then((data: any) => {
          this.context.commit('SET_COMPANY_ACTIVITIES_BY_FRIMEWORK', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivityById (activity_id?: number) {
    //ACA TENGO QUE REVISAR LAS URLS
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .getActivityById(activity_id)
        .then((data: any) => {
          this.context.commit('SET_SELECTED_ACTIVITY', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivityByGap (gap_id: number) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .getActivityByGap(gap_id)
        .then((data: any) => {
          this.context.commit('SET_SELECTED_ACTIVITY_ARRAY', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public checkActivityTask (data: any) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .checkActivityTask(data.task_id, data.completed)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true, })
  public updateActivity (data: { [key: string]: any }) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .updateActivity(
          data.act_id,
          data.status,
          data.url,
          data.activity_urls_attributes,
          data.datarooms_data,
          data.user_id
        )
        ?.then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivitiesComments (data: any) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .getActivitiesComments(data)
        .then((data: any) => {
          this.context.commit('SET_COMMENTS', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public postActivitiesComment (data: any) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .postActivitiesComment(data.act_id, data.message)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public putActivitiesComment (data: any) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .putActivitiesComment(data.act_id, data.message_id, data.message)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public deleteActivitiesComment (data: any) {
    return new Promise<ActivitiesState[]>((resolve, reject) => {
      tapi
        .deleteActivitiesComment(data.act_id, data.message_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  // Getters
  get ActivitiesByFrimework () {
    return [...this.ActivitiesState.selectedFrimeworkActivities,]
  }

  get CompanyActivities () {
    return [...this.ActivitiesState.CompanyActivities,]
  }

  get getSelectedActivity () {
    return { ...this.ActivitiesState.SelectedActivity, }
  }
  get getSelectedActivityArray () {
    return [...this.ActivitiesState.SelectedActivityArray,]
  }
  get getComments () {
    return [...this.ActivitiesState.comments,]
  }

  get getMetaData () {
    return this.ActivitiesState.metadata
  }

  get getModalStatus () {
    return this.ActivitiesState.modal_finish
  }
  get getStatusFilter () {
    return this.ActivitiesState.status_filter
  }

}
