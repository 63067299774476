import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import store from '@/store'
import jwt_decode from 'jwt-decode'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/callback-cms',
  },
  {
    path: '/callback-cms',
    name: 'Callback',
    component: () => import('@/views/Auth/Callback/CallbackView.vue'),
  },
  {
    path: '/recovery-password',
    name: 'Recovery-Password',
    component: () => import('@/views/Auth/RecoveryPassword/Index.vue'),
  },
  {
    path: '/change-password',
    name: 'Change-Password',
    component: () => import('@/views/Auth/ChangePassword/Index.vue'),
  },
  {
    path: '/confirm-account',
    name: 'Confirm-Account',
    component: () => import('@/views/Auth/ChangePassword/Index.vue'),
  },
  {
    path: '/cheatseet',
    name: 'Cheatseet-Component',
    component: () => import('@/views/Cheatseet/Index.vue'),
  },
  {
    path: '/app',
    component: () => import('@/layouts/App/Index.vue'),
    meta: { requiresAuth: true, role: true },
    children: [
      {
        path: '/',
        redirect: 'clients',
      },
      {
        path: '/intercom',
        beforeEnter() {
          window.open('https://intercom.help/hackmetrix/es')
        },
      },
      {
        path: '/termsandconditions',
        name: 'Terms-And-Conditions',
        component: () => import('@/views/TermsAndConditions/TermsAndConditionsView.vue'),
      },
      {
        path: 'templates',
        name: 'templates',
        component: () => import('@/views/Implementador/Templates/Index.vue'),
      },
      {
        path: 'templates/create',
        name: 'templateCreate',
        component: () => import('@/views/Implementador/Templates/Template.vue'),
      },
      {
        path: 'templates/:id/edit',
        name: 'templateEdit',
        component: () => import('@/views/Implementador/Templates/Template.vue'),
      },
      {
        path: 'clients',
        name: 'clients',
        component: () => import('@/views/Implementador/Clients/Index.vue'),
      },
      {
        path: 'clients/create',
        name: 'clientsCreate',
        component: () => import('@/views/Implementador/Clients/CreateBusiness.vue'),
      },
      {
        path: 'clients/:id',
        component: () => import('@/layouts/Clients/Index.vue'),
        // name: 'clientby',
        children: [
          {
            path: '/',
            name: 'category',
            component: () => import('@/views/Shared/Category/Index.vue'),
          },
          {
            path: 'category/:id_category/',
            name: 'controlsList',
            component: () => import('@/views/Shared/Controls/Index.vue'),
          },
          {
            path: 'category/:id_category/detail_control/:id_control',
            name: 'detailControl',
            component: () => import('@/views/Shared/DetailControl/Index.vue'),
          },
          {
            path: 'category/:id_category/edit_control/:id_control',
            name: 'editControl',
            component: () => import('@/views/Shared/EditControl/Index.vue'),
          },
          {
            path: 'dataroom',
            name: 'clientDataroom',
            component: () => import('@/views/Implementador/Clients/DataRoom.vue'),
          },
          {
            path: 'plan',
            name: 'clientPlan',
            component: () => import('@/views/Implementador/Clients/Plan.vue'),
          },
          {
            path: 'plan/:task_id',
            name: 'activityDetail',
            component: () => import('@/views/Shared/ImplementationDetail/Index.vue'),
          },
          {
            path: 'filebusiness',
            name: 'clientFileBusiness',
            component: () => import('@/views/Implementador/Clients/FileBusiness.vue'),
          },
          {
            path: 'editBusiness',
            name: 'clientEditFileBusiness',
            component: () => import('@/views/Implementador/Clients/EditBusiness.vue'),
          },
          {
            path: 'compliance',
            name: 'complianceSummary',
            component: () => import('@/views/Implementador/ComplianceSummary/Index.vue'),
          },
          {
            path: 'history',
            name: 'clientHistory',
            component: () => import('@/views/Implementador/Clients/History.vue'),
          },
          {
            path: 'history/dashboard',
            name: 'DashboardGap',
            component: () => import('@/views/Shared/Dashboard/Index.vue'),
          },
          {
            path: 'history/dashboard/category/:id_category/',
            name: 'historyControlsList',
            component: () => import('@/views/Shared/Controls/Index.vue'),
          },
          {
            path: 'history/dashboard/category/:id_category/detail_control/:id_control',
            name: 'historyDetailControl',
            component: () => import('@/views/Shared/DetailControl/Index.vue'),
          },
          {
            path: 'contacts',
            name: 'contacts',
            component: () => import('@/views/Implementador/Clients/Contact.vue'),
          },
          {
            path: 'documents',
            name: 'documentsClient',
            component: () => import('@/views/Shared/Documents/Index.vue'),
          },
          {
            path: 'maintenance/:activity_id',
            name: 'maintenanceDetailClient',
            component: () => import('@/views/Shared/DocumentsDetail/Index.vue'),
          },
          {
            path: 'maintenance/:activity_id/stepper',
            name: 'steppsDocumentsClient',
            component: () => import('@/views/Shared/DocumentsSteps/Index.vue'),
          },
        ],
      },
      {
        path: 'compliance',
        name: 'complianceStartup',
        component: () => import('@/views/Shared/ComplianceSummary/Index.vue'),
      },
      // Gestor de Frameworks
      {
        path: 'frameworks',
        name: 'frameworks',
        component: () => import('@/views/Implementador/Frameworks/Index.vue'),
      },
      {
        path: 'frameworks/:id',
        name: 'frameworksDetail',
        component: () => import('@/views/Implementador/Frameworks/DetailFramework.vue'),
      },
      {
        path: 'frameworks/:id/category/:category',
        name: 'frameworksCategory',
        component: () => import('@/views/Implementador/Frameworks/DetailCategory.vue'),
      },
    ],
  },
  {
    path: '/startup',
    name: 'Startup',
    component: () => import('@/layouts/App/Index.vue'),
    meta: { requiresAuth: true, role: true },
    children: [
      {
        path: '/applications',
        name: 'SPM',
        beforeEnter() {
          window.location.replace(process.env.VUE_APP_URL_SPM_REDIRECT || '')
        },
      },
      {
        path: '/access',
        name: 'Access',
        beforeEnter() {
          window.location.replace(process.env.VUE_APP_URL_ACCESS_REDIRECT || '')
        },
      },
      {
        path: '/intercom',
        beforeEnter() {
          window.open('https://intercom.help/hackmetrix/es')
        },
      },
      {
        path: 'activity',
        name: 'activity',
        component: () => import('@/views/Startup/ImplementationV2/Index.vue'),
      },
      {
        path: 'activity/:task_id',
        name: 'activityDetailClient',
        component: () => import('@/views/Shared/ImplementationDetail/Index.vue'),
      },
      //Documents
      {
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/Shared/Documents/Index.vue'),
      },
      {
        path: 'maintenance/:activity_id',
        name: 'maintenanceDetail',
        component: () => import('@/views/Shared/DocumentsDetail/Index.vue'),
      },
      {
        path: 'maintenance/:activity_id/stepper',
        name: 'steppsDocuments',
        component: () => import('@/views/Shared/DocumentsSteps/Index.vue'),
      },
      // Analysis Gap
      {
        path: 'project/:id',
        name: 'project',
        component: () => import('@/views/Startup/Project/Index.vue'),
        props: { id: true },
      },
      {
        path: 'project/category/:id_category/',
        component: () => import('@/layouts/StartupGap/Index.vue'),
        children: [
          {
            path: '/',
            name: 'startupControlsList',
            component: () => import('@/views/Shared/Controls/Index.vue'),
          },
          {
            path: 'detail_control/:id_control',
            name: 'startupDetailControl',
            component: () => import('@/views/Shared/DetailControl/Index.vue'),
          },
        ],
      },
      {
        path: 'project/:id/control-detail',
        name: 'controlDetail',
        component: () => import('@/views/Startup/Project/ControlDetail.vue'),
      },
      // Dataroom
      {
        path: 'dataroom/:id?',
        name: 'startupDataroom',
        component: () => import('@/views/Startup/Dataroom/Index.vue'),
      },
      // Gestion de aplicaciones
      {
        path: 'applications',
        name: 'applications',
        component: () => import('@/views/Startup/Applications/Index.vue'),
      },
      {
        path: 'applications/add',
        name: 'addapplications',
        component: () => import('@/views/Startup/Applications/AddApplication.vue'),
      },
      {
        path: 'applications/form',
        name: 'formApplication',
        component: () => import('@/views/Startup/Applications/Form.vue'),
      },
      {
        path: 'applications/form/:id',
        name: 'editApplication',
        component: () => import('@/views/Startup/Applications/EditForm.vue'),
      },
      {
        path: 'applications/:id/configuration',
        name: 'configurationApp',
        component: () => import('@/views/Startup/Applications/Configuration.vue'),
      },
      // History versions
      {
        path: 'history',
        component: () => import('@/layouts/History/Index.vue'),
        children: [
          {
            path: '/',
            name: 'startupHistory',
            component: () => import('@/views/Startup/History/Index.vue'),
          },
          {
            path: 'dashboard',
            name: 'startupDashboardGap',
            component: () => import('@/views/Shared/Dashboard/Index.vue'),
          },
          {
            path: 'dashboard/category/:id_category/',
            name: 'historyStartupControlsList',
            component: () => import('@/views/Shared/Controls/Index.vue'),
          },
          {
            path: 'dashboard/category/:id_category/detail_control/:id_control',
            name: 'historyStartupDetailControl',
            component: () => import('@/views/Shared/DetailControl/Index.vue'),
          },
        ],
      },
      {
        path: 'compliance',
        name: 'compliance',
        component: () => import('@/views/Shared/ComplianceSummary/Index.vue'),
      },
    ],
  },
  {
    path: '/hackmetrix-maintenance',
    name: 'hackmetrix-maintenance',
    component: () => import('@/views/MaintenancePage/Index.vue'),
  },
  {
    path: '/*',
    name: 'NotFound',
    component: () => import('@/views/NotFound/Index.vue'),
  },
]

const scrollBehavior = (to: Route, from: Route, savedPosition: any) => {
  if (to.hash && to.name === 'activity') {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          selector: to.hash,
          behavior: 'smooth',
        })
      }, 1000)
    })
  }
  if (to.hash && to.name === 'clientPlan') {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          selector: to.hash,
          behavior: 'smooth',
        })
      }, 1000)
    })
  } else {
    return { x: 0, y: 0, behavior: 'smooth' }
  }

  if (savedPosition) {
    return savedPosition
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
})
const fullPath = ''

router.beforeEach((to, from, next) => {
  if(process.env.VUE_APP_CONTEXT != 'local'){
    const token = store.getters['AuthModule/getUserToken'].token
    if(!(['/', '/callback-cms', '/callback', '/logout'].includes(to.path))){
      if (token) {
        const jwt: any = jwt_decode(token)
        const exp = jwt.exp * 1000
        var current_time = Date.now()
        if (exp < current_time) {
          window.location.replace(`${process.env.VUE_APP_BASE_URL}/logout`)
          store.commit('AuthModule/SET_TOKEN_AUTH')
        }
      } else {
        window.location.replace(`${process.env.VUE_APP_BASE_URL}/logout`)
        store.commit('AuthModule/SET_TOKEN_AUTH')
      }
    }
  }
  next()
})

router.afterEach(() => {
  const Stickedtooltips = document.querySelectorAll('.vs-tooltip')
  for (const tooltip of Stickedtooltips) {
    tooltip.remove()
  }
})
export default router
