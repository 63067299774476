import axios from 'axios'
import store from '../store'

// Interfaces
import { TemplateCrudRecomendation } from '@/store/types/Dataroom/CrudTemplate'
import { CrudBusiness } from '@/store/types/Business/CrudBusiness'
import { ApplicationInterface } from '@/store/types/Applications/Application'
import { FoldersInterface } from '@/store/types/Dataroom/Folder'
import { FileUploadInterface } from '@/store/modules/dataroom/dataroomModule'
import { TemplateGetInterface } from '@/store/modules/recomendations/recomendationModule'
import { EditGapInterface } from '@/store/types/Analysis/EditGap'
import { ResetPasswordInterface } from '@/store/types/ResetPassword/Index'
import { ContactsInterface } from '@/store/types/Business/Contacts'
import { faro } from '@grafana/faro-web-sdk'
import { UsersInterface } from '@/store/types/Business/Users'

const hackmetrixUrl = process.env.VUE_APP_IMPLEMENTATION_BASE_API
const rbacUrl = process.env.VUE_APP_RBAC_BASE_API
const evidenceUrl = process.env.VUE_APP_EVIDENCE_BASE_API
const settingsUrl = process.env.VUE_APP_SETTINGS_BASE_API
const commandCenter = process.env.VUE_APP_COMMAND_CENTER_API
const disabledMessages = ['Tu cuenta no tiene permisos para acceder a este recurso']
const ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(',') || []

const settingsAxios = axios.create({
  baseURL: settingsUrl,
})

const commandCenterAxios = axios.create({
  baseURL: commandCenter,
})

const taxios = axios.create({
  baseURL: hackmetrixUrl,
})

const uaxios = axios.create({
  baseURL: rbacUrl,
})

const docaxios = axios.create({
  baseURL: evidenceUrl,
})

const multiPartData = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
}

function transformData(data: any, isDocument?: boolean) {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))

  if (data.datarooms_data) {
    data.datarooms_data.forEach((element: any) => {
      isDocument
        ? formData.append('files', element.dataFormData)
        : formData.append('files[]', element.dataFormData)
    })
  }

  return formData
}

taxios.interceptors.response.use(
  (response: any) => {
    if (response.data.message) {
      store.dispatch('NotificactionsModule/OpenNotification', {
        message: response.data.message,
        color_notification: 'black',
      })
    }
    return response
  },
  (error: any) => {
    const { response = {} } = error
    if (response.status === 422) {
      if (response.data.errors.status) {
        store.dispatch('NotificactionsModule/OpenNotification', {
          message: response.data.errors.status,
          color_notification: 'danger',
        })
      } else if (response.data.message) {
        store.dispatch('NotificactionsModule/OpenNotification', {
          message: response.data.message,
          color_notification: 'danger',
        })
      }
      return
    }
    if (!disabledMessages.includes(response.data.message)) {
      response.data
        ? store.dispatch('NotificactionsModule/OpenNotification', {
            message: response.data.message,
            color_notification: 'danger',
          })
        : store.dispatch('NotificactionsModule/OpenNotification', {
            message: 'Error inesperado por favor contacte con soporte',
            color_notification: 'danger',
          })
    }

    return Promise.reject(error)
  }
)

taxios.interceptors.request.use(
  async (config) => {
    config.headers.common['Authorization'] = store.getters['AuthModule/getUserToken'].token
    config.headers.common['Content-Type'] = 'aplication/json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

docaxios.interceptors.response.use(
  (response: any) => {
    if (response.data.message) {
      store.dispatch('NotificactionsModule/OpenNotification', {
        message: response.data.message,
        color_notification: 'black',
      })
    }
    return response
  },
  (error: any) => {
    const { response = {} } = error

    response.data
      ? store.dispatch('NotificactionsModule/OpenNotification', {
          message: response.data.message,
          color_notification: 'danger',
        })
      : store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error inesperado por favor contacte con soporte',
          color_notification: 'danger',
        })

    return Promise.reject(error)
  }
)

docaxios.interceptors.request.use(
  async (config) => {
    config.headers.common['Authorization'] = store.getters['AuthModule/getUserToken'].token
    config.headers.common['Content-Type'] = 'aplication/json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

commandCenterAxios.interceptors.request.use(
  async (config) => {
    config.headers.common['Authorization'] = store.getters['AuthModule/getUserToken'].token
    config.headers.common['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.reject(new Error('Error setting headers: ' + error));
  }
)

settingsAxios.interceptors.request.use(
  async (config) => {
    config.headers.common['Authorization'] = store.getters['AuthModule/getUserToken'].token
    config.headers.common['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

uaxios.interceptors.request.use(
  async (config) => {
    config.headers.common['Authorization'] = store.getters['AuthModule/getUserToken'].token
    config.headers.common['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.reject(error instanceof Error ? error : new Error(error))
  }
)

// Auth Services
function Login() {
  return taxios.get('/api/v2/users/autenticate_user')
}

function ForgotPassword(email: string) {
  return taxios.post('/users/password', {
    user: {
      email,
    },
  })
}

function ResetPassword(data: ResetPasswordInterface) {
  return taxios.put('/users/password', {
    user: {
      password: data.password,
      password_confirmation: data.password_confirmation,
      reset_password_token: data.reset_password_token,
    },
  })
}
function Logout() {
  return taxios.delete('/logout')
}

//Modules
function GetModule() {
  return uaxios.get(`/module`)
}

// Polimorfic Services
function GetFrameworks() {
  return taxios.get('/api/v2/frameworks')
}
function GetCategories() {
  return taxios.get('/api/v2/frameworks')
}
function GetResources() {
  return taxios.get('/api/v2/resources')
}

function GetUsersByCompany(company_id: number) {
  return taxios.get(`/api/v2/companies/${company_id}/user_list`)
}

function GetControlsByFramework(framework: number) {
  return taxios.get(`/api/v2/frameworks/${framework}/controls`)
}

function GetTemplateSmall() {
  return taxios.get('/api/v2/templates/list_small')
}
// Templates

function GetTemplateExist(data: TemplateCrudRecomendation) {
  const controls = data.controls_id ? data.controls_id : []
  let query = ''
  const url = `/api/v2/templates/already_exist?template_type=${data.template_type}`

  let queryEnd = ''

  controls.forEach((element) => {
    query = query + '&control_ids[]=' + element.toString()
  })

  queryEnd = url + query

  return taxios.get(queryEnd)
}

function GetTemplates(data: TemplateGetInterface) {
  let url = `/api/v2/templates?page=${data.page}`

  if (data.title_contains) {
    url = url + `&query[title_cont]=${data.title_contains}`
  }

  if (data.query) {
    url = url + `&query[tags_contains_by]=${data.query}`
  }

  return taxios.get(url)
}

function GetTemplatesV2(data: TemplateGetInterface) {
  let url = `/api/v2/templates?page=${data.page}`

  if (data.title_contains) {
    url = url + `&query[title_cont]=${data.title_contains}`
  }

  if (data.query) {
    url = url + `&query[tags_contains_by]=${data.query}`
  }

  return taxios.get(url)
}

function GetTemplatesbyId(data: number) {
  return taxios.get(`/api/v1/templates/${data}`)
}
function CreateTemplate(data: TemplateCrudRecomendation): Promise<TemplateCrudRecomendation> {
  return taxios.post('/api/v1/templates', {
    template: {
      title: data.title,
      description: data.description,
      tags: data.tags,
      datarooms_data: data.datarooms_data,
    },
  })
}

function CreateTemplateV2(data: TemplateCrudRecomendation): Promise<TemplateCrudRecomendation> {
  return taxios.post('/api/v2/templates', {
    template: {
      title: data.title,
      description: data.description,
      framework: data.framework,
      status: data.status,
      control_ids: data.controls_id,
      template_type: data.template_type,
      datarooms_data: data.datarooms_data,
    },
  })
}

function EditTemplate(data: TemplateCrudRecomendation): Promise<TemplateCrudRecomendation> {
  return taxios.put(`/api/v1/templates/${data.id}`, {
    template: {
      title: data.title,
      description: data.description,
      tags: data.tags,
      datarooms_delete_ids: data.datarooms_delete_ids,
      datarooms_data: data.datarooms_data,
    },
  })
}

function EditTemplateV2(data: TemplateCrudRecomendation): Promise<TemplateCrudRecomendation> {
  return taxios.put(`/api/v1/templates/${data.id}`, {
    template: {
      title: data.title,
      description: data.description,
      framework: data.framework,
      status: data.status,
      control_ids: data.controls_id,
      template_type: data.template_type,
      datarooms_data: data.datarooms_data,
    },
  })
}
function DeleteTemplate(data: any) {
  return taxios.delete('/api/v1/templates/delete_multiple/', {
    data: {
      template_ids: data,
    },
  })
}

// Business
function GetBusiness(data: TemplateGetInterface) {
  let url = `/api/v2/companies?page=${data.page}`

  if (data.name_cont) {
    url = url + `&query[name_cont]=${data.name_cont}`
  }

  return taxios.get(url)
}

function ExistBusiness(business: string) {
  return taxios.get(`/api/v1/companies/available_name?company_name=${business}`)
}

function GetDetailBusiness(data: number) {
  return taxios.get(`/api/v1/companies/${data}`)
}

function CreateBusiness(data: CrudBusiness): Promise<CrudBusiness> {
  return taxios.post('/api/v1/companies', {
    company: {
      name: data.name,
      rut_cuit: data.rut_cuit,
      description: data.description,
      documentation_level: data.documentation_level,
      architectures: data.architectures,
      architecture_description: data.architecture_description,
      framework_ids: data.framework_ids,
      saq_id: data.saq_id,
      contacts_attributes: data.contacts_attributes,
      channels: data.channels,
      datarooms_data: data.kickoff_data,
      domains: data.domains,
      hubspot_id: data.hubspot_id,
    },
  })
}
function EditBusiness(data: CrudBusiness): Promise<CrudBusiness> {
  return taxios.put(`/api/v1/companies/${data.id}`, {
    company: {
      name: data.name,
      rut_cuit: data.rut_cuit,
      description: data.description,
      documentation_level: data.documentation_level,
      architectures: data.architectures,
      architecture_description: data.architecture_description,
      framework_ids: data.framework_ids,
      saq_id: data.saq_id,
      contacts_attributes: data.contacts_attributes,
      active: data.active,
      channels: data.channels,
      datarooms_data: data.kickoff_data,
      domains: data.domains,
      hubspot_id: data.hubspot_id,
    },
  })
}
function SoftDeleteBusiness(data: CrudBusiness): Promise<CrudBusiness> {
  return taxios.put(`/api/v1/companies/${data.id}`, {
    company: {
      active: false,
    },
  })
}
function DeleteBusiness(data: any) {
  return taxios.delete(`/api/v1/companies/${data.id}`)
}
function HubspotIdAvailibility(hubspot_id: number) {
  return taxios.get('api/v2/companies/available_hubspot_id', {
    params: {
      hubspot_id: hubspot_id,
    },
  })
}
// Dataroom
function GetFolders(data: any) {
  return taxios.get(`/api/v2/folders/${data}`)
}
function CreateFolder(data: string, folderId: number) {
  return taxios.post('/api/v1/folders', {
    folder: {
      name: data,
    },
    parent_id: folderId,
  })
}
function EditFolder(data: FoldersInterface): Promise<FoldersInterface> {
  return taxios.put(`/api/v1/folders/${data.id}`, {
    folder: {
      name: data.name,
    },
  })
}
function DeleteFolder(data: any) {
  return taxios.delete(`/api/v1/folders/${data}`)
}
function DeleteFile(data: any) {
  return taxios.delete(
    `/api/v1/folders/${store.getters['DataRoomModule/getFolderSelected']}/remove_file/${data}`
  )
}
function UploadFile(data: any, file: FileUploadInterface) {
  return taxios.post(`/api/v1/folders/${data}/upload_file`, {
    folder: {
      datarooms_data: file,
    },
  })
}

// GapAnalisis
function GetGapCategories(companyId: number) {
  const framework_selected = store.getters['PolimorficModule/getFrameworkSelected'].id

  if (
    store.state.AuthModule.AuthState.role.includes('Implementador') ||
    store.state.AuthModule.AuthState.role.includes('Customer Success')
  ) {
    const frameworkId = companyId
    const businessId = store.getters['AuthModule/getCompanyIdSelected']

    return taxios.get(`/api/v1/companies/${businessId}/frameworks/${frameworkId}/categories`)
  } else {
    return taxios.get(
      `/api/v1/companies/${companyId}/frameworks/${framework_selected}/categories?version=${store.getters['GapModule/getVersionCategories'].version_categories}`
    )
  }
}

function GetControlList(categoryId: number, frameworkId?: string) {
  let businessId = 0
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  const framework_selected = store.getters['PolimorficModule/getFrameworkSelected'].id

  if (
    store.state.AuthModule.AuthState.role.includes('Implementador') ||
    store.state.AuthModule.AuthState.role.includes('Customer Success')
  ) {
    return taxios.get(
      `/api/v1/companies/${businessId}/frameworks/${frameworkId}/categories/${categoryId}/controls`
    )
  } else {
    return taxios.get(
      `/api/v1/companies/${businessId}/frameworks/${framework_selected}/categories/${categoryId}/controls`
    )
  }
}
function GetGapAnalysisControl(gapId: number) {
  if (store.getters['GapModule/getVersionCategories'].version_categories === 0) {
    return taxios.get(`/api/v1/gap_analyses/${gapId}`)
  } else {
    return taxios.get(
      `/api/v1/gap_analyses/${gapId}?version=${store.getters['GapModule/getVersionCategories'].version_categories}`
    )
  }
}
function EditGapAnalysys(data: EditGapInterface) {
  return taxios.put(
    `/api/v1/gap_analyses/${store.getters['GapModule/getDetailSelected'].selected.gap_analysis.id}`,
    {
      gap_analysis: data,
    }
  )
}
function PublicGapAnalysis(data: number) {
  return taxios.post(`/api/v1/companies/${data}/snapshots`, {
    snapshot: {
      framework_id: store.getters['PolimorficModule/getFrameworkSelected'].id,
      completed: store.getters['GapModule/getCompletedInformation'].completedInformation,
      pending: store.getters['GapModule/getPendingInformation'].pendingInformation,
    },
  })
}
function ExportGapAnalysis(companyId: number) {
  return taxios
    .get(`/api/v1/companies/${companyId}/export_to_xlsx`, {
      responseType: 'blob',
    })
    .then((response) => {
      let filename = ''
      const disposition = response.headers['content-disposition']
      if (disposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      const url = URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${filename}`)
      document.body.appendChild(link)
      link.click()
    })
}

function ExportToJira(companyId: number) {
  return taxios.get(`/api/v1/companies/${companyId}/export_to_jira`, {
    responseType: 'blob',
  })
}

// Applications Integration //

function GetApps() {
  return taxios.get('/api/v1/plugins')
}

function GetAppById(data: any) {
  return taxios.get(`/api/v1/plugins/${data}`)
}

function AddApp(data: ApplicationInterface): Promise<ApplicationInterface> {
  return taxios.post('/api/v1/plugins', {
    plugin: {
      application: data.application,
      email: data.email,
      api_token: data.api_token,
      domain: data.domain,
      project_name: data.project_name,
    },
  })
}

function UpdateApp(data: ApplicationInterface): Promise<ApplicationInterface> {
  return taxios.put(`/api/v1/plugins/${data.id}`, {
    plugin: {
      application: data.application,
      email: data.email,
      api_token: data.api_token,
      domain: data.domain,
      project_name: data.project_name,
    },
  })
}

function DeleteApp(data: any) {
  return taxios.delete(`/api/v1/plugins/${data}`)
}

// History the advance //

// Get date for snapshots

function GetDatesSnapshots(data: number) {
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    return taxios.get(
      `/api/v1/companies/${store.getters['AuthModule/getCompanyId'].company_id}/snapshots`
    )
  } else {
    return taxios.get(`/api/v1/companies/${data}/snapshots`)
  }
}

function GetHistoryProgress(data: number) {
  return taxios.get(`/api/v1/companies/${data}/progress_history`)
}

// users

function GetCompanyUsers(data: Array<any>) {
  return uaxios.get(`/user/list?page=${data[0]}&perPage=20&companyId=${data[1]}`)
}

function EditCompanyUser(user_id: string, userEditData: UsersInterface) {
  return uaxios.patch(`/user/${user_id}`, userEditData)
}

function BlockCompanyUserId(user_id: string) {
  return uaxios.patch(`/user/block/${user_id}`)
}

function UnblockCompanyUserId(user_id: string) {
  return uaxios.patch(`/user/unblock/${user_id}`)
}

function SendChangePasswordUser(data: UsersInterface) {
  return uaxios.post(`/user/change-password`, data)
}

// contacts

function GetCompanyContacts(id: number) {
  return taxios.get(`/api/v1/companies/${id}/contacts`)
}

function GetCompanyContactId(id: number, contact_id: number) {
  return taxios.get(`/api/v1/companies/${id}/contacts/${contact_id}`)
}

function SetCompanyMainContactId(id: number, contact_id: number) {
  return taxios.put(`/api/v1/companies/${id}/contacts/${contact_id}/set_as_main`)
}

function DeactivateContactId(id: number, contact_id: number, new_main_contact_id?: number) {
  return taxios.put(
    `/api/v2/companies/${id}/contacts/${contact_id}/deactivate_account`,
    new_main_contact_id
      ? {
          new_main_contact_id,
        }
      : null
  )
}

function ActivateContactId(id: number, contact_id: number) {
  return taxios.put(`/api/v2/companies/${id}/contacts/${contact_id}/activate_account`)
}

function PostCompanyContact(id: number, contact: ContactsInterface) {
  return taxios.post(`/api/v1/companies/${id}/contacts`, {
    contact: {
      ...contact,
    },
  })
}

function PostEditCompanyContact(id: number, contact_id: number, contact: ContactsInterface) {
  return taxios.put(`/api/v1/companies/${id}/contacts/${contact_id}`, {
    contact: {
      ...contact,
    },
  })
}

function deleteCompanyContact(id: number, contact_id: number, new_main_contact_id?: number) {
  return taxios.delete(
    `/api/v1/companies/${id}/contacts/${contact_id}`,
    new_main_contact_id
      ? {
          data: {
            new_main_contact_id,
          },
        }
      : undefined
  )
}

function SendInvite(id: number, contact_id: number) {
  return taxios.post(`/api/v2/companies/${id}/contacts/${contact_id}/create_account`, {})
}

function reSendInvite(id: number, contact_id: number) {
  return taxios.put(`/api/v1/companies/${id}/contacts/${contact_id}/confirmation_instructions`, {})
}

function ConfirmUser(data: ResetPasswordInterface) {
  return taxios.put('/users/confirmation', {
    user: {
      password: data.password,
      password_confirmation: data.password_confirmation,
      confirmation_token: data.confirmation_token,
    },
  })
}

// metrics
function getUncomplies(framework_id?: number) {
  let framework_selected = 0
  const version = store.getters['GapModule/getVersionCategories'].version_categories

  if (framework_id) {
    framework_selected = framework_id
  } else {
    const framework = store.getters['PolimorficModule/getFrameworkSelected'].id
    const framework_history = store.getters['GapModule/getframeworkIdSelectedHistory'].id
    version > 0 ? (framework_selected = framework_history) : (framework_selected = framework)
  }
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  const query = version == 0 ? '' : `?version=${version}`

  return taxios.get(
    `/api/v1/metrics/companies/${businessId}/frameworks/${framework_selected}/total_controls_uncomplied${query}`
  )
}

function getCompliesCat() {
  const framework_selected = store.getters['PolimorficModule/getFrameworkSelected'].id
  const framework_history = store.getters['GapModule/getframeworkIdSelectedHistory'].id

  let framework_finale

  const version = store.getters['GapModule/getVersionCategories'].version_categories

  version > 0 ? (framework_finale = framework_history) : (framework_finale = framework_selected)

  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  const query = version == 0 ? '' : `?version=${version}`

  return taxios.get(
    `/api/v1/metrics/companies/${businessId}/frameworks/${framework_finale}/compliance_level${query}`
  )
}

function getCompliesCatbyFramework(framework: number) {
  const version = store.getters['GapModule/getVersionCategories'].version_categories
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  const query = version == 0 ? '' : `?version=${version}`

  return taxios.get(
    `/api/v1/metrics/companies/${businessId}/frameworks/${framework}/compliance_level${query}`
  )
}

function getCompliesTotal(framework_id?: number) {
  let framework_selected = 0
  const version = store.getters['GapModule/getVersionCategories'].version_categories

  if (framework_id) {
    framework_selected = framework_id
  } else {
    const framework = store.getters['PolimorficModule/getFrameworkSelected'].id
    const framework_history = store.getters['GapModule/getframeworkIdSelectedHistory'].id
    version > 0 ? (framework_selected = framework_history) : (framework_selected = framework)
  }
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  const query = version == 0 ? '' : `?version=${version}`

  return taxios.get(
    `/api/v1/metrics/companies/${businessId}/frameworks/${framework_selected}/total_complies${query}`
  )
}

// Command center
function getEvidences(name: string) {
  const response = commandCenterAxios.get(`/allEvidences?name=${name}`)
  return response
}

// Settings
function getByCompanyTermsAndConditions() {
  const response = settingsAxios.get(`/company/terms-and-conditions`)
  return response
}

function acceptTermsAndConditions(data: any) {
  return settingsAxios.post(`/company/terms-and-conditions`, data)
}

// Activities

function getCompanyActivities() {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.get(`/api/v1/companies/${businessId}/activities`)
}

function getCompanyActivitiesV2(data: any) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  const url = `/api/v2/companies/${businessId}/activities/implementation?name=${
    data.name ? data.name : ''
  }`

  let query = ''

  let queryEnd = ''

  data.status.forEach((element: any) => {
    query = query + '&status[]=' + element.toString()
  })

  queryEnd = url + query

  return taxios.get(queryEnd)
}

function getActivitiesByFrimework(control_id?: number) {
  const framework_selected = store.getters['PolimorficModule/getFrameworkSelected'].id
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  const query = control_id ? `?gap_analysis_id=${control_id}` : ''
  return taxios.get(
    `/api/v2/companies/${businessId}/frameworks/${framework_selected}/activities${query}`
  )
}

function getActivityById(activity_id?: number) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  return taxios.get(`/api/v2/companies/${businessId}/activities/${activity_id}`)
}

function getActivityByGap(id: number) {
  const gap_id = id ? id : store.getters['GapModule/getDetailSelected'].selected.gap_analysis.id
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  return taxios.get(`/api/v1/companies/${businessId}/gap_analyses/${gap_id}/activities`)
}

function checkActivityTask(task_id: number, completed: boolean) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.put(`/api/v1/companies/${businessId}/tasks/${task_id}`, {
    task: {
      completed,
    },
  })
}

function updateActivity(
  act_id: number,
  status: string,
  url: string,
  activity_urls_attributes: { [key: string]: any }[],
  datarooms_data: any[],
  user_id: number
) {
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  let body: any
  if (status) {
    body = {
      status,
    }
    return taxios.put(`/api/v2/companies/${businessId}/activities/${act_id}`, {
      activity: body,
    })
  }
  if (url) {
    body = {
      url,
    }
  }
  if (activity_urls_attributes) {
    body = {
      activity_urls_attributes,
    }
  }
  if (datarooms_data) {
    body = {
      datarooms_data,
    }
  }
  if (user_id) {
    body = {
      user_id,
    }
  }
  return taxios.put(`/api/v2/companies/${businessId}/activities/${act_id}`, {
    activity: body,
  })
}

function getActivitiesComments(act_id: number) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  return taxios.get(`/api/v1/companies/${businessId}/activities/${act_id}/comments`)
}

function postActivitiesComment(act_id: number, message: string) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.post(`/api/v1/companies/${businessId}/activities/${act_id}/comments`, {
    comment: {
      description: message,
    },
  })
}

function putActivitiesComment(act_id: number, message_id: number, message: string) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.put(`/api/v1/companies/${businessId}/activities/${act_id}/comments/${message_id}`, {
    comment: {
      description: message,
    },
  })
}

function deleteActivitiesComment(act_id: number, message_id: number) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.delete(
    `/api/v1/companies/${businessId}/activities/${act_id}/comments/${message_id}`
  )
}

function finishedSummary(act_id: number) {
  return taxios.get(`/api/v2/activities/${act_id}/finished_summary`)
}

// ----- Maintenance -------

function getCompanyMaintenance(dateSort?: string) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.get(
    `/api/v2/companies/${businessId}/activities/maintenance?order=${dateSort ? dateSort : 'asc'}`
  )
}

function getActivityByIdMaintenance(activity_id?: number) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.get(`/api/v2/companies/${businessId}/activities/${activity_id}?maintenance=true`)
}

function updateActivityMaintenance(
  act_id: number,
  status: string,
  updated_at: string,
  seen_at: string,
  expire_at: string,
  time_expired: boolean | string,
  released_at: string,
  activity_urls_attributes: { [key: string]: any }[],
  datarooms_data: any[]
) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  let body: any

  if (status) {
    body = {
      maintenance_status: status,
    }
  }
  if (updated_at) {
    body = {
      updated_at,
    }
  }
  if (expire_at) {
    body = {
      expire_at,
    }
  }
  if (time_expired) {
    body = {
      time_expired,
    }
  }
  if (seen_at) {
    body = {
      seen_at,
    }
  }
  if (released_at) {
    body = {
      released_at,
    }
  }
  if (activity_urls_attributes) {
    body = {
      activity_urls_attributes,
    }
  }
  if (datarooms_data) {
    body = {
      datarooms_data,
    }
  }

  return taxios.put(`/api/v2/companies/${businessId}/activities/${act_id}?maintenance=true`, {
    activity: body,
  })
}

function postActivityMaintenance(act_id: number, justification: string, datarooms_data: any[]) {
  return taxios.post(`/api/v2/activities/${act_id}/evidence_logs`, {
    evidence_log: {
      justification: justification,
      datarooms_data: datarooms_data,
    },
  })
}

function finishedActivity(act_id: number, released_at: string) {
  let businessId

  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  return taxios.post(`/api/v2/companies/${businessId}/activities/${act_id}/finish`, {
    evidence_log: {
      released_at: released_at,
    },
  })
}

//-------------------  Evidencias -------------------
// Create Evidence Doc  al momento de Pulsar el boton de Actualizar Documentacion
function postCreateEvidenceDoc(data: any) {
  return docaxios.post('/create', data)
}

// Get informacion del Documento Por ID
function getDocumentDetailById(data: any) {
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  return docaxios.get(
    `/getByCompanyIdActivityId/${businessId}/${data.act_id}?companyId=${businessId}`
  )
}

// Get Listado de documentos por compañia
function getByCompanyId() {
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }

  return docaxios.get(`/getByCompanyId/${businessId}?companyId=${businessId}`)
}
function sendNotificationAlert(data: any) {
  return docaxios.post(`/sendNotificationResponsable`, data)
}

function nextStepStepper(data: any) {
  const dataMultipart = transformData(data, true)
  return docaxios.post('/nextStep', dataMultipart, multiPartData)
}

function postPreviousStep(data: any) {
  return docaxios.post('/previousStep', data)
}

function postUpdateWithoutChange(data: any) {
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['BusinessModule/getSelectedProduct'].id
  }

  const description = {
    description: data.description,
  }
  return docaxios.post(`/update/${businessId}/${data.act_id}`, description)
}

function getHistoryDocument(data: any) {
  let businessId
  if (
    !ADMIN_ROLES.some((role: any) => store.getters['AuthModule/getUserRole'].role.includes(role))
  ) {
    businessId = store.getters['AuthModule/getCompanyId'].company_id
  } else {
    businessId = store.getters['AuthModule/getCompanyIdSelected']
  }
  return docaxios.get(
    `/getRevisionHistoryByCompanyIdActivityId/${businessId}/${data}?companyId=${businessId}`,
    data
  )
}

// Steppers Implementation API

// Listado de Steper

function getSteppers(act_id: number) {
  return taxios.get(`/api/v2/activities/${act_id}/stepper`)
}

// Post Stepper Start

function startActivity(act_id: number) {
  return taxios.post(`/api/v2/activities/${act_id}/start`)
}

// Post Stepper request_review

function requestReview(act_id: number) {
  return taxios.post(`/api/v2/activities/${act_id}/request_review`)
}

// Post Stepper request_review

function requestBack(act_id: number) {
  return taxios.post(`/api/v2/activities/${act_id}/step_back`)
}

// Post reject changes and completed step

function rejectChanges(data: any) {
  const template = {
    activity: {
      comments_attributes: [
        {
          description: data.description,
        },
      ],
    },
  }

  return taxios.post(`/api/v2/activities/${data.act_id}/reject_changes`, template)
}

// Post Stepper approve Changes

function approveChanges(act_id: number) {
  return taxios.post(`/api/v2/activities/${act_id}/approve_changes`)
}

// Post Save Approvation

function saveApprove(data: any) {
  const dataMultipart = transformData(data)

  return taxios.post(
    `/api/v2/activities/${data.act_id}/save_approbation`,
    dataMultipart,
    multiPartData
  )
}

// Post Save Comunication

function saveComunication(data: any) {
  const dataMultipart = transformData(data)

  return taxios.post(
    `/api/v2/activities/${data.act_id}/save_comunication`,
    dataMultipart,
    multiPartData
  )
}

// Save Final Document

function saveFinalDocument(data: any) {
  const dataMultipart = transformData(data)

  return taxios.post(
    `/api/v2/activities/${data.act_id}/save_final_document`,
    dataMultipart,
    multiPartData
  )
}

// get step Files

function getFilesDocuments(act_id: number) {
  return taxios.get(`/api/v2/activities/${act_id}/get_step_files`)
}

// Delete step Files

function deleteFilesDocuments(data: any) {
  return taxios.delete(`/api/v2/activities/${data.act_id}/file/${data.file_id}`)
}

export default {
  // Auth Services
  Login,
  Logout,
  ForgotPassword,
  ResetPassword,
  // Polymorphic Services
  GetFrameworks,
  GetCategories,
  GetResources,
  GetUsersByCompany,
  GetTemplateSmall,
  GetControlsByFramework,
  // Recommendations Services
  GetTemplates,
  GetTemplatesbyId,
  CreateTemplate,
  EditTemplate,
  DeleteTemplate,
  GetTemplatesV2,
  CreateTemplateV2,
  EditTemplateV2,
  GetTemplateExist,
  // Business Services
  GetBusiness,
  ExistBusiness,
  CreateBusiness,
  EditBusiness,
  SoftDeleteBusiness,
  DeleteBusiness,
  GetDetailBusiness,
  HubspotIdAvailibility,
  // Dataroom
  GetFolders,
  CreateFolder,
  EditFolder,
  DeleteFolder,
  DeleteFile,
  UploadFile,
  // Gap Analysis
  GetGapCategories,
  GetControlList,
  GetGapAnalysisControl,
  // Edit Gap Analysis
  EditGapAnalysys,
  // Public Gap Analisis
  PublicGapAnalysis,
  // Export Gap Analysis
  ExportGapAnalysis,
  ExportToJira,
  // Application
  GetApps,
  GetAppById,
  AddApp,
  UpdateApp,
  DeleteApp,
  // History snapshots
  GetDatesSnapshots,
  GetHistoryProgress,
  // user
  GetCompanyUsers,
  GetModule,
  EditCompanyUser,
  BlockCompanyUserId,
  UnblockCompanyUserId,
  SendChangePasswordUser,
  // contact
  GetCompanyContacts,
  GetCompanyContactId,
  PostCompanyContact,
  PostEditCompanyContact,
  deleteCompanyContact,
  SetCompanyMainContactId,
  DeactivateContactId,
  ActivateContactId,
  SendInvite,
  ConfirmUser,
  getUncomplies,
  getCompliesCat,
  getCompliesTotal,
  reSendInvite,
  getCompliesCatbyFramework,
  // Command center
  getEvidences,
  // Settings
  getByCompanyTermsAndConditions,
  acceptTermsAndConditions,
  // Activities
  getActivitiesByFrimework,
  getCompanyActivities,
  getCompanyActivitiesV2,
  getActivityById,
  getActivityByGap,
  checkActivityTask,
  updateActivity,
  getActivitiesComments,
  postActivitiesComment,
  putActivitiesComment,
  deleteActivitiesComment,
  finishedSummary,
  // ----- Maintenance -------
  getCompanyMaintenance,
  getActivityByIdMaintenance,
  updateActivityMaintenance,
  postActivityMaintenance,
  finishedActivity,
  // Stepers Maintenance
  getSteppers,
  startActivity,
  requestReview,
  requestBack,
  rejectChanges,
  approveChanges,
  saveApprove,
  saveComunication,
  saveFinalDocument,
  getFilesDocuments,
  deleteFilesDocuments,
  // Documents
  // Evidences
  getByCompanyId,
  sendNotificationAlert,
  getDocumentDetailById,
  postCreateEvidenceDoc,
  nextStepStepper,
  postPreviousStep,
  postUpdateWithoutChange,
  getHistoryDocument,
}
