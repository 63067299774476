import { Mutation, VuexModule, Module, Action, } from 'vuex-module-decorators'

import tapi from '@/boot/tapi'

export interface ArraySteppers {
  step: number;
  name: string;
  user_name: string;
  is_completed: boolean;
  completed_at: any;
}

export interface SteppersDocumentState {
  activitys: any[];
  documents: any[];
  history_documents: DocumentationHistoryDataInterface[];
}

export interface DocumentsDetailDataInterface {
  id: number;
  name: string;
  urlDownload: string;
}

export interface DocumentationHistoryDataInterface {
  description: string;
  documents: DocumentsDetailDataInterface[];
  id: number;
  updatedAt: string;
  userName: string;
  version: number;
}

@Module({
  namespaced: true,
})
export default class StepperDocumentModule extends VuexModule {
  SteppersDocumentState: SteppersDocumentState = {
    activitys: [],
    documents: [],
    history_documents: [],
  }

  // Action
  // Get informacion del Documento Por ID
  @Action
  public getDocumentDetailById (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .getDocumentDetailById(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  // Create Evidence Doc  al momento de Pulsar el boton de Actualizar Documentacion

  @Action
  public postCreateEvidenceDoc (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .postCreateEvidenceDoc(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action({rawError: true})
  public getByCompanyId () {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .getByCompanyId()
        .then((data: any) => {
          this.context.commit('SET_ACTIVITYS', data.data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public sendNotificationAlert (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .sendNotificationAlert(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  // Action Step Start

  @Action
  public nexStep (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .nextStepStepper(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public postPreviousStep (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .postPreviousStep(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public getFilesDocuments (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .getFilesDocuments(act_id)
        .then((data: any) => {
          this.context.commit('SET_DOCUMENTS_STEPPER', data.data.files)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public deleteFilesDocuments (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .deleteFilesDocuments(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  // Send justification without updates
  @Action
  public updateWithoutChangeDocuments (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .postUpdateWithoutChange(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  // getHistoryDocuments
  @Action
  public getHistoryDocument (data: any) {
    return new Promise<DocumentationHistoryDataInterface[]>((resolve, reject) => {
      tapi
        .getHistoryDocument(data)
        .then((data: any) => {
          this.context.commit('SET_HISTORY_DOCUMENTS', data.data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Mutation
  public SET_DOCUMENTS_STEPPER (data: any) {
    this.SteppersDocumentState.documents = data
  }

  @Mutation
  public SET_ACTIVITYS (data: any) {
    this.SteppersDocumentState.activitys = data
  }

  @Mutation
  public SET_HISTORY_DOCUMENTS (data: any) {
    data.forEach((element: any) => {
      element.open = false
    })
    this.SteppersDocumentState.history_documents = data
  }

  get getHistoryData () {
    return this.SteppersDocumentState.history_documents
  }
}
