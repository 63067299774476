import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import { DocumentInterface, } from '@/store/types/Documents/DocumentsInterfaces'
import { UrlsInterface, } from '@/store/modules/maintenance/MaintenanceModule'
import tapi from '@/boot/tapi'

export interface DocumentState {
  SelectedDocument: DocumentInterface;
  urls: any;
}

@Module({
  namespaced: true,
})
export default class DocumentModule extends VuexModule {
  DocumentState: DocumentState = {
    SelectedDocument: {},
    urls: [],
  }

  // ------------ Mutations --------------
  @Mutation
  public SET_SELECTED_ACTIVITY (data: DocumentInterface) {
    this.DocumentState.SelectedDocument = data
  }

  @Mutation
  public SET_URLS_ARRAY (data: UrlsInterface) {
    this.DocumentState.urls = data
  }

  // ------------ Action ---------------------
  @Action
  public getDocumentById (act_id: number) {
    return new Promise<DocumentState[]>((resolve, reject) => {
      tapi
        .getDocumentDetailById(act_id)
        .then((data: any) => {
          this.context.commit('SET_SELECTED_ACTIVITY', data.data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  // ---------------- Getters -----------------
  get getDocumentDetailById () {
    return this.DocumentState.SelectedDocument
  }

  get getUrlArray () {
    return this.DocumentState.urls
  }

  get checkEveryFrecuency (){
    return this.DocumentState.SelectedDocument.checkEvery
  }
}
