import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import { NotificationInfoInterface, } from '@/store/types/Notifications/Notifications'

export interface NotificationState {
  notification_info: NotificationInfoInterface;
  show_notification: boolean;
}

@Module({
  namespaced: true,
})
export default class NotificactionsModule extends VuexModule {
  NotificationState: NotificationState = {
    notification_info: {
      message: '',
      color_notification: '',
      icon_notification: '',
      // action: '',
    },
    show_notification: false,
  }

  // Mutations
  @Mutation
  public SET_INFO_NOTIFICATION (data: NotificationInfoInterface) {
    this.NotificationState.notification_info = data
  }

  @Mutation
  public SET_SHOW_NOTIFICATION (data: boolean) {
    this.NotificationState.show_notification = data
  }


  @Action
  public OpenNotification (data: NotificationInfoInterface ) {    
    this.context.commit('SET_INFO_NOTIFICATION', data)
    this.context.commit('SET_SHOW_NOTIFICATION', true)
    setTimeout(() => {
      this.context.commit('SET_SHOW_NOTIFICATION', false)
    }, data.timeout || 4000)
  }
  @Action
  public CloseNotification () {
    this.context.commit('SET_SHOW_NOTIFICATION', false)
  }

  //getters

  get getNotificationInfo () {
    return this.NotificationState.notification_info
  }
  get getShowNotification () {
    return this.NotificationState.show_notification
  }

}
