import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import { ApplicationInterface, } from '@/store/types/Applications/Application'
import router from '@/router'

import tapi from '@/boot/tapi'

export interface ApplicationState {
  apps: ApplicationInterface[];
  appSelected: ApplicationInterface;
}

@Module({
  namespaced: true,
})
export default class ApplicationModule extends VuexModule {
  ApplicationState: ApplicationState = {
    apps: [],
    appSelected: {
      id: 0,
      application: '',
      email: '',
      api_token: '',
      domain: '',
      project_name: '',
      created_at: '',
      updated_at: '',
    },
  }

  // Mutations
  @Mutation
  public SET_USER_APPLICATIONS (data: ApplicationInterface[]) {
    this.ApplicationState.apps = data
  }

  @Mutation
  public SET_APPLICATION_SELECTED (data: ApplicationInterface) {
    this.ApplicationState.appSelected = data
  }

  @Mutation
  public UPDATE_APPLICATION (data: ApplicationInterface) {
    const templateIndex = this.ApplicationState.apps.findIndex((p) => p.id === data.id)
    Object.assign(this.ApplicationState.apps[templateIndex], data)
  }

  // @Action 
  // public getApps () {
  //   return new Promise<ApplicationInterface[]>((resolve) => {
  //     tapi
  //       .GetApps()
  //       .then((data: any) => {
  //         this.context.commit('SET_USER_APPLICATIONS', data.data)
  //         resolve(data)
  //       })
  //       .catch((reject) => {
  //         if (reject.response.status === 401) {
  //           router.push('/')
  //           location.reload()
  //         }
  //       })
  //   })
  // }

  // @Action 
  // public updateApp (template: ApplicationInterface) {
  //   return new Promise((resolve, reject) => {
  //     tapi
  //       .UpdateApp(template)
  //       .then((data: any) => {
  //         this.context.commit('UPDATE_APPLICATION', data.data.company)
  //         resolve(data)
  //       })
  //       .catch(reject)
  //   })
  // }

  // @Action 
  // public addApp (template: ApplicationInterface) {
  //   return new Promise((resolve, reject) => {
  //     tapi
  //       .AddApp(template)
  //       .then((data: any) => {
  //         this.context.commit('SET_NEW_BUSINESS', data.data.company)
  //         resolve(data)
  //       })
  //       .catch(reject)
  //   })
  // }

  // @Action
  // public deleteApp (template: number) {
  //   return new Promise((resolve, reject) => {
  //     tapi
  //       .DeleteApp(template)
  //       .then((data: any) => {
  //         resolve(data)
  //       })
  //       .catch(reject)
  //   })
  // }
}
