import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import tapi from '@/boot/tapi'
import { HistoryProgressInterface, ChangeControlInterface, } from '@/store/types/History/History'

export interface HistoryState {
  snapshots: HistoryProgressInterface[];
  historyNav: ChangeControlInterface[];
  versionGap: number;
}

@Module({
  namespaced: true,
})
export default class HistoryModule extends VuexModule {
  HistoryState: HistoryState = {
    snapshots: [],
    historyNav: [],
    versionGap: 0,
  }

  @Mutation
  public SET_SNAPSHOTS (data: HistoryProgressInterface[]) {
    this.HistoryState.snapshots = data
  }

  @Mutation
  public SET_HISTORY_NAV (data: ChangeControlInterface[]) {
    this.HistoryState.historyNav = data
  }

  // Actions
  @Action({rawError: true})
  public getHistory (data: number) {
    return new Promise<HistoryProgressInterface[]>((resolve, reject) => {
      tapi
        .GetHistoryProgress(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
}
