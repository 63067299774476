interface Config {
  growthbookKey: string | undefined;
  growthbookEnableDevMode: string | boolean | undefined;
  growthbookUrlApi: string | undefined;
}

const useEnvironment = (): Config => {
  const config: Config = {
    growthbookKey: process.env.VUE_APP_GROWTHBOOK_API_KEY,
    growthbookEnableDevMode: process.env.VUE_APP_GROWTHBOOK_ENABLE_DEV_MODE,
    growthbookUrlApi: process.env.VUE_APP_URL_GROWTHBOOK_API,
  };

  return config;
};

export default useEnvironment;
