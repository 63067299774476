import { Action, VuexModule, Module, } from 'vuex-module-decorators'
import tapi from '@/boot/tapi'

export interface UniversalSearchState {
  dataSearch: any[];
}

@Module({
  namespaced: true,
})
export default class UniversalSearchModule extends VuexModule {
  UniversalSearchState: UniversalSearchState = {
    dataSearch: [],
  }

  // Actions
  @Action
  public async getInfoBySearch(name: string) {
    this.UniversalSearchState.dataSearch = [];
    try {
      const response = await tapi.getEvidences(name);
      if (response.status === 200) {
        this.UniversalSearchState.dataSearch = response.data;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Getters
  get getDataSearch() {
    return this.UniversalSearchState.dataSearch;
  }
}
