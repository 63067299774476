import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import { TermAndConditionsInterface } from '@/store/types/Settings/TermsAndConditions'
import tapi from '@/boot/tapi'

export interface SettingsState {
  terms_and_conditions: any[];
}

@Module({
  namespaced: true,
})
export default class SettingsModule extends VuexModule {
  SettingsState: SettingsState = {
    terms_and_conditions: [],
  }

  // Mutations
  @Mutation
  public SET_TERMS_AND_CONDITIONS (data: any) {
    this.SettingsState.terms_and_conditions = data
  }

// Actions
@Action
public getCompanyTermsAndConditions () {
  return new Promise<TermAndConditionsInterface[]>((resolve, reject) => {
    tapi
      .getByCompanyTermsAndConditions()
      .then((data: any) => {
        const tyc = data.data.data
        this.context.commit('SET_TERMS_AND_CONDITIONS', tyc);
        resolve(tyc)
      })
      .catch((error) => {
        reject()
      })
  })
}

@Action
public acceptTermsAndConditions (data: any) {
  return new Promise<any[]>((resolve, reject) => {
    tapi
      .acceptTermsAndConditions(data)
      .then((data: any) => {
        resolve(data)
      })
      .catch(() => {
        reject()
      })
  })
}


  // Getters
  get getTermsAndConditions() {
    return this.SettingsState.terms_and_conditions;
  }
}
