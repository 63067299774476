import { Mutation, VuexModule, Module, } from 'vuex-module-decorators'

export interface SearchState {
  searchResults: unknown;
  searchText: string;
}

@Module({
  namespaced: true,
})
export default class SearchModule extends VuexModule {
  SearchState: SearchState = {
    searchResults: [],
    searchText: '',
  }

  @Mutation
  public SET_SEARCH_RESULTS (data: []) {
    this.SearchState.searchResults = data
  }
  @Mutation
  public SET_SEARCH_TEXT (data: string) {
    this.SearchState.searchText = data
  }

  get getSearchText () {
    return this.SearchState.searchText
  }
}
