import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthModule, { AuthState, } from './modules/auth/AuthModule'
import RecomendationModule, {
  RecomendationState,
} from './modules/recomendations/recomendationModule'
import BusinessModule, { BusinessState, } from './modules/business/businessModule'
import PolimorficModule, { PolimorficState, } from './modules/polimorfic/polimorficModule'
import DataRoomModule, { DataRoomState, } from './modules/dataroom/dataroomModule'
import ApplicationModule, { ApplicationState, } from './modules/application/ApplicationModule'
import GapModule, { GapState, } from './modules/gap/gapModule'
import SearchModule, { SearchState, } from './modules/search/searchModule'
import HistoryModule, { HistoryState, } from './modules/history/historyModule'
import MetricsModule, { MetricsState, } from './modules/metrics/metricsModule'
import ActivitiesModule, { ActivitiesState, } from './modules/activities/ActivitiesModule'
import NotificactionsModule, {
  NotificationState,
} from './modules/notifications/notificationsModule'
import MaintenanceModule, { MaintenanceState, } from './modules/maintenance/MaintenanceModule'
import SteppersModule, { SteppersState, } from './modules/steppers/SteppersModule'
import DocumentModule, { DocumentState, } from './modules/documents/DocumentsModule'
import StepperDocumentModule, {
  SteppersDocumentState,
} from './modules/steppers/StepperDocumentModule'
import SettingsModule, { SettingsState, } from './modules/settings/settingsModule'
import UniversalSearchModule, { UniversalSearchState, } from './modules/universalSearch/universalSearchModule'

Vue.use(Vuex)

export interface State {
  AuthModule: {
    AuthState: AuthState;
  };
  RecomendationModule: {
    RecomendationState: RecomendationState;
  };
  BusinessModule: {
    BusinessState: BusinessState;
  };
  PolimorficModule: {
    PolimorficState: PolimorficState;
  };
  DataRoomModule: {
    DataRoomModule: DataRoomState;
  };
  GapModule: {
    GapModule: GapState;
  };
  SearchModule: {
    SearchModule: SearchState;
  };
  ApplicationModule: {
    ApplicationModule: ApplicationState;
  };
  HistoryModule: {
    HistoryModule: HistoryState;
  };
  MetricsModule: {
    MetricsModule: MetricsState;
  };
  ActivitiesModule: {
    ActivitiesState: ActivitiesState;
  };
  NotificactionsModule: {
    NotificationState: NotificationState;
  };
  MaintenanceModule: {
    MaintenanceState: MaintenanceState;
  };
  SteppersModule: {
    SteppersState: SteppersState;
  };
  StepperDocumentModule: {
    SteppersDocumentState: SteppersDocumentState;
  };
  DocumentModule: {
    DocumentState: DocumentState;
  };
  SettingsModule: {
    SettingsState: SettingsState;
  };
  UniversalSearchModule: {
    UniversalSearchState: UniversalSearchState;
  };
}

export default new Vuex.Store({
  modules: {
    AuthModule,
    ActivitiesModule,
    RecomendationModule,
    BusinessModule,
    PolimorficModule,
    DataRoomModule,
    GapModule,
    SearchModule,
    ApplicationModule,
    HistoryModule,
    MetricsModule,
    NotificactionsModule,
    MaintenanceModule,
    SteppersModule,
    StepperDocumentModule,
    DocumentModule,
    SettingsModule,
    UniversalSearchModule,
  },
  plugins: [createPersistedState(),],
  mutations: {},
  strict: process.env.DEV === 'true',
})
