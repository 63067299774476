import { Mutation, VuexModule, Module, Action, } from 'vuex-module-decorators'
import tapi from '@/boot/tapi'

export interface ArraySteppers {
  step: number;
  name: string;
  user_name: string;
  is_completed: boolean;
  completed_at: any;
}

export interface SteppersState {
  steppers: ArraySteppers[];
  steppersStay: ArraySteppers;
  documents: any[];
  date_approve: string;
  modal_finish: boolean;
  finish_modal_info: any;
}

@Module({
  namespaced: true,
})
export default class SteppersModule extends VuexModule {
  SteppersState: SteppersState = {
    steppers: [],
    steppersStay: {
      step: 0,
      name: '',
      user_name: '',
      is_completed: false,
      completed_at: '',
    },
    documents: [],
    date_approve: '',
    modal_finish: false,
    finish_modal_info: {},
  }

  // Action
  @Action
  public getSteppersActivity (act_id: number) {
    return new Promise<ArraySteppers[]>((resolve, reject) => {
      tapi
        .getSteppers(act_id)
        .then((data: any) => {
          this.context.commit('SET_STEPPERS', data.data.stepper.steps)

          let data_stepper = {}

          if (data.data.stepper.current_step === null) {
            data_stepper = {
              step: 0,
              name: '',
              user_name: '',
              is_completed: false,
              completed_at: '',
            }
          } else {
            data_stepper = data.data.stepper.current_step
          }
          this.context.commit('SET_STEPPER_STAY', data_stepper)

          resolve(data.data.stepper)
        })
        .catch(() => {
          reject()
        })
    })
  }

  // Action Step Start

  @Action
  public startActivity (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .startActivity(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public rejectChanges (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .rejectChanges(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public requestReview (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .requestReview(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public requestBack (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .requestBack(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public approveChanges (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .approveChanges(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public saveApprove (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .saveApprove(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public saveComunication (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .saveComunication(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public saveFinalDocument (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .saveFinalDocument(act_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public getFilesDocuments (act_id: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .getFilesDocuments(act_id)
        .then((data: any) => {
          this.context.commit('SET_DOCUMENTS_STEPPER', data.data.data.files)
          this.context.commit('SET_DATE_APPROVE', data.data.data.approval_date)
          resolve(data)
        })
        .catch(reject)
    })
  }
  @Action
  public deleteFilesDocuments (data: any) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .deleteFilesDocuments(data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public finishedSummary (data: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .finishedSummary(data)
        .then((data: any) => {
          this.context.commit('SET_FINISH_INFO', data.data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Mutation
  public SET_STEPPERS (data: ArraySteppers[]) {
    this.SteppersState.steppers = data
  }

  @Mutation
  public SET_STEPPER_STAY (data: ArraySteppers) {
    this.SteppersState.steppersStay = data
  }

  @Mutation
  public SET_DOCUMENTS_STEPPER (data: any) {
    this.SteppersState.documents = data
  }

  @Mutation
  public SET_DATE_APPROVE (data: any) {
    this.SteppersState.date_approve = data
  }

  @Mutation
  public SET_FINISH_INFO (data: any) {
    this.SteppersState.finish_modal_info = data
  }

  @Mutation
  public SET_OPEN_MODAL (data: boolean) {
    this.SteppersState.modal_finish = data
  }

  get getSteppers () {
    return this.SteppersState.steppers
  }

  get getStepperStay () {
    return this.SteppersState.steppersStay
  }

  get getDocumentsStepper () {
    return this.SteppersState.documents
  }

  get getDateApprove () {
    return this.SteppersState.date_approve
  }
  
  get getModalStatus () {
    return this.SteppersState.modal_finish
  }

  get getFinishedInfo () {
    return this.SteppersState.finish_modal_info
  }
}
