import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import { ResetPasswordInterface, } from '@/store/types/ResetPassword/Index'
import Vue from 'vue'
import { faro } from '@grafana/faro-web-sdk';
import tapi from '@/boot/tapi'
import { FrameworkInterface } from '@/store/types/Polimorfic/Framework';

export interface AuthState {
  email: string;
  role: string;
  token: string;
  company_id: number;
  company_name: string;
  folder_id: number;
  isLogged: boolean;
  name: string;
  last_name: string;
  id: number;
  intercom_hmac_code: string;
  companySelected: selectedCompanyImplementer;


}
export interface UserDataToken {
  idToken: any;
  token: string;

}
export interface Frameworks {
  available_snapshot: boolean;
  id: number;
  name: string;
  status: string;
}
export interface Folder {
  id: number;
}

export interface selectedCompanyImplementer {
  id: string;
  name: string;
  frameworks: Frameworks[]
  folder:Folder
}

@Module({
  namespaced: true,
})
export default class AuthModule extends VuexModule {
  AuthState: AuthState = {
    email: '',
    role: '',
    token: '',
    company_id: 0,
    company_name: '',
    isLogged: false,
    folder_id: 0,
    name: '',
    last_name: '',
    id: 0,
    intercom_hmac_code: '',
    companySelected: {
      id: '', name: '',frameworks:[], folder:{id:0}
    }

  }



  // Mutations
  @Mutation
  public SET_USER_AUTH(data: AuthState) {
    this.AuthState = { ...this.AuthState, ...data, }

  }
  @Mutation
  public SET_TOKEN_AUTH() {
    this.AuthState.token = ""
  }
  @Mutation
  public INITIAL_STATE() {
    this.AuthState.email = ''
    this.AuthState.role = ''
    this.AuthState.folder_id = 0
    this.AuthState.isLogged = false,
      this.AuthState.token = ''
    this.AuthState.id = 0
    this.AuthState.intercom_hmac_code = ''
    this.AuthState.companySelected = { id: '', name: '',frameworks:[], folder:{id:0} }

  }
  @Mutation
  public SET_COMPANY_ID_SELECTED(value: string) {
    this.AuthState.companySelected.id = value
  }
  @Mutation
  public SET_COMPANY_NAME_SELECTED(value: string) {
    this.AuthState.companySelected.name = value
  }
  @Mutation
  public SET_COMPANY_FRAMEWORKS_SELECTED(value:Frameworks[]) {
    this.AuthState.companySelected.frameworks = value
  }
  @Mutation
  public SET_COMPANY_FOLDER_ID_SELECTED(value:number) {
    this.AuthState.companySelected.folder.id = value
  }
  // Actions
  @Action
  public Login() {
    return new Promise((resolve, reject) => {
      tapi
        .Login()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })


    })
  }
  @Action
  public Logout() {
    return new Promise((resolve, reject) => {
      tapi
        .Logout()
        .then((res) => {
          this.context.commit('INITIAL_STATE')
          resolve(res)
        })
        .catch(reject)
    })
  }

  @Action
  public ForgotPassword(data: string) {
    return new Promise((resolve, reject) => {
      tapi
        .ForgotPassword(data)
        .then((res) => {
          resolve(res)
        })
        .catch(reject)
    })
  }

  @Action
  public ResetPassword(data: ResetPasswordInterface) {
    return new Promise((resolve, reject) => {
      tapi
        .ResetPassword(data)
        .then((res) => {
          resolve(res)
        })
        .catch(reject)
    })
  }

  // getters
  get getUserToken() {
    return {
      token: this.AuthState.token,
      role: this.AuthState.role,
      id: this.AuthState.id,
      intercom_hmac_code: this.AuthState.intercom_hmac_code,
    }
  }
  get isAuth() {
    return this.AuthState.isLogged
  }
  get getUserRole() {
    return {
      role: this.AuthState.role,
    }
  }

  get getCompanyId() {
    return {
      company_id: this.AuthState.company_id,
    }
  }

  get getNameUser() {
    return {
      user_name: this.AuthState.name,
    }
  }

  get getUser() {
    return {
      user: this.AuthState,
    }
  }
  get getCompanyIdSelected() {
    return this.AuthState.companySelected.id
  }
  get getCompanyNameSelected() {
    return this.AuthState.companySelected.name
  }
  get getCompanyFrameworksSelected() {
    return this.AuthState.companySelected.frameworks.sort((a: any, b: any) =>  {return a.name > b.name ? 1 : -1})
  }
  get getCompanyFolderIdSelected() {
    return this.AuthState.companySelected.folder.id
  }
}
