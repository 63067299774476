import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import { MaintenanceInterface, MaintenanceComent, } from '@/store/types/Maintenance/MaintenanceGeneral'
import tapi from '@/boot/tapi'

export interface MaintenanceState {
  CompanyActivities: MaintenanceInterface[];
  selectedFrimeworkActivities: MaintenanceInterface[];
  SelectedActivity: MaintenanceInterface;
  comments: MaintenanceComent[];
  SelectedActivityArray: MaintenanceInterface[];
}

export interface UrlsInterface {
  link?: string;
  id?: string;
  _destroy?: boolean;
  url?: string;
}

@Module({
  namespaced: true,
})
export default class MaintenanceModule extends VuexModule {
  MaintenanceState: MaintenanceState = {
    CompanyActivities: [],
    selectedFrimeworkActivities: [],
    SelectedActivity: {},
    comments: [],
    SelectedActivityArray: [],
  }

  // Mutations
  @Mutation
  public SET_COMPANY_ACTIVITIES (data: MaintenanceInterface[]) {
    this.MaintenanceState.CompanyActivities = data
  }

  @Mutation
  public SET_COMPANY_ACTIVITIES_BY_FRIMEWORK (data: MaintenanceInterface[]) {
    this.MaintenanceState.selectedFrimeworkActivities = data
  }

  @Mutation
  public SET_SELECTED_ACTIVITY (data: MaintenanceInterface) {
    this.MaintenanceState.SelectedActivity = data
    if (this.MaintenanceState.SelectedActivity.url === null) {
      this.MaintenanceState.SelectedActivity.url = ''
    }
  }

  @Mutation
  public SET_SELECTED_ACTIVITY_ARRAY (data: MaintenanceInterface[]) {
    this.MaintenanceState.SelectedActivityArray = data
  }

  @Mutation
  public SET_COMMENTS (data: MaintenanceComent[]) {
    this.MaintenanceState.comments = data
  }

  @Mutation
  public SET_COMMENTS_ARRRAY (data: any) {
    this.MaintenanceState.SelectedActivityArray[data.index]['comments'] = data.messages
  }

  // Action
  @Action
  public getCompanyMaintenance (dateSort?: string) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .getCompanyMaintenance(dateSort)
        .then((data: any) => {
          const activities = data.data.data
          this.context.commit('SET_COMPANY_ACTIVITIES', activities)
          resolve(data.data.data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivitiesByFrimework (control_id?: number) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .getActivitiesByFrimework(control_id)
        .then((data: any) => {
          this.context.commit('SET_COMPANY_ACTIVITIES_BY_FRIMEWORK', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivityById (activity_id?: number) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .getActivityByIdMaintenance(activity_id)
        .then((data: any) => {
          this.context.commit('SET_SELECTED_ACTIVITY', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivityByGap (gap_id: number) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .getActivityByGap(gap_id)
        .then((data: any) => {
          this.context.commit('SET_SELECTED_ACTIVITY_ARRAY', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public checkActivityTask (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .checkActivityTask(data.task_id, data.completed)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true, })
  public updateActivity (data: {[key: string]: any}) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .updateActivityMaintenance(data.act_id, data.status, data.update_at, data.seen_at, data.expire_at, data.released_at, data.activity_urls_attributes, data.datarooms_data, data.time_expired)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public getActivitiesComments (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .getActivitiesComments(data)
        .then((data: any) => {
          this.context.commit('SET_COMMENTS', data.data)
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public postActivitiesComment (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .postActivitiesComment(data.act_id, data.message)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public putActivitiesComment (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .putActivitiesComment(data.act_id, data.message_id, data.message)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public deleteActivitiesComment (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .deleteActivitiesComment(data.act_id, data.message_id)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public postActivityMaintenance (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .postActivityMaintenance(data.act_id, data.justification, data.datarooms_data)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action
  public finishedActivity (data: any) {
    return new Promise<MaintenanceState[]>((resolve, reject) => {
      tapi
        .finishedActivity(data.act_id, data.released_at)
        .then((data: any) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }

  // Getters
  get ActivitiesByFrimework () {
    return [...this.MaintenanceState.selectedFrimeworkActivities,]
  }

  get CompanyActivities () {
    return [...this.MaintenanceState.CompanyActivities,]
  }

  get getSelectedActivity () {
    return { ...this.MaintenanceState.SelectedActivity, }
  }
  get getSelectedActivityArray () {
    return [...this.MaintenanceState.SelectedActivityArray,]
  }
  get getComments () {
    return [...this.MaintenanceState.comments,]
  }
}
