import { Action, Mutation, VuexModule, Module, } from 'vuex-module-decorators'
import tapi from '../../../boot/tapi'
import { CategoriesInterface, } from '@/store/types/Polimorfic/Categories'
import { FrameworkInterface, } from 'src/store/types/Polimorfic/Framework'
import { ResourcesInterface, } from '@/store/types/Polimorfic/Resources'
import { BusinessInterface, } from '@/store/types/Business/Business'

export interface PolimorficState {
  frameworks: FrameworkInterface[];
  categories: CategoriesInterface[];
  framework_selected: FrameworkInterface;
  openModalActivity: boolean;
  resources: ResourcesInterface[];
  usersByCompany: any[];
  saqs: BusinessInterface[];
}

@Module({
  namespaced: true,
})
export default class PolimorficModule extends VuexModule {
  PolimorficState: PolimorficState = {
    frameworks: [],
    categories: [],
    framework_selected: {
      name: '',
      id: 0,
    },
    openModalActivity: false,
    resources: [],
    usersByCompany: [],
    saqs: [],
  }

  // Mutations
  @Mutation
  public SET_USER_FRAMEWORKS (data: FrameworkInterface[]) {
    this.PolimorficState.frameworks = data
  }

  @Mutation
  public SET_USER_CATEGORIES (data: CategoriesInterface[]) {
    this.PolimorficState.categories = data
  }

  @Mutation
  public SET_FRAMEWORK_SELECTED (data: FrameworkInterface) {
    this.PolimorficState.framework_selected = data
  }

  @Mutation
  public SET_SAQS (data: BusinessInterface[]) {
    this.PolimorficState.saqs = data
  }

  @Mutation
  public SET_STATUS_MODAL (openModal?: any) {
    this.PolimorficState.openModalActivity = openModal ? openModal : false
  }

  @Mutation
  public SET_RESOURCES (data: ResourcesInterface[]) {
    this.PolimorficState.resources = data
  }

  @Mutation
  public SET_USERS_BY_COMPANY (data: any[]) {
    this.PolimorficState.usersByCompany = data
  }

  //Actions

  @Action
  public getFrameworks () {
    return new Promise<FrameworkInterface[]>((resolve, reject) => {
      tapi
        .GetFrameworks()
        .then((data: any) => {
          this.context.commit('SET_USER_FRAMEWORKS', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public getSaqs () {
    return new Promise<FrameworkInterface[]>((resolve, reject) => {
      tapi
        .GetFrameworks()
        .then((data: any) => {
          this.context.commit('SET_SAQS', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public GetUsersByCompany (data: number) {
    return new Promise<any[]>((resolve, reject) => {
      tapi
        .GetUsersByCompany(data)
        .then((data: any) => {
          this.context.commit('SET_USERS_BY_COMPANY', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public getCategories () {
    return new Promise<CategoriesInterface[]>((resolve, reject) => {
      tapi
        .GetCategories()
        .then((data: any) => {
          this.context.commit('SET_USER_CATEGORIES', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public openModal (openModal?: any) {
    return new Promise<void>((resolve) => {
      this.context.commit('SET_STATUS_MODAL', openModal)
      resolve()
    })
  }

  @Action
  public getResources () {
    return new Promise<ResourcesInterface[]>((resolve, reject) => {
      tapi
        .GetResources()
        .then((data: any) => {
          this.context.commit('SET_RESOURCES', data.data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  @Action
  public getControlsByFramework (data: number) {
    return new Promise<ResourcesInterface[]>((resolve, reject) => {
      tapi
        .GetControlsByFramework(data)
        .then((data: any) => {
          resolve(data.data.data)
        })
        .catch(reject)
    })
  }

  //getters

  get getFramework () {
    return this.PolimorficState.frameworks
  }

  get getSaq () {
    return this.PolimorficState.saqs
  }

  get getCategorie () {
    return {
      public: this.PolimorficState.categories,
    }
  }

  get getFrameworkSelected () {
    return this.PolimorficState.framework_selected
  }

  get getStatusModal () {
    return this.PolimorficState.openModalActivity
  }

  get getResource () {
    return this.PolimorficState.resources
  }

  get getUsersCompany () {
    return this.PolimorficState.usersByCompany
  }
}
