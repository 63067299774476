
import { Component, Vue, } from 'vue-property-decorator'
import { mapGetters, mapActions, } from 'vuex'

@Component({
  components: {},
  methods: {
    ...mapActions('NotificactionsModule', ['CloseNotification',]),
  },
  computed: {
    ...mapGetters('NotificactionsModule', ['getNotificationInfo', 'getShowNotification',]),
  },
})
export default class ChipComponent extends Vue {}
